<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-07 17:18:10
 * @FilePath: \vue-official-website-of-xiaomi-master\src\views\Index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <Header />
    <Lunbo />
    <PageMain />
    <Bottom />

  </div>
</template>

<script>
import Header from '@/components/header'
import Lunbo from '@/components/Lunbo'
import PageMain from '@/components/PageMain'
import Bottom from "@/components/PageMain/Bottom";



export default {
  name: 'App',
  components: {
    Header,
    Lunbo,
    PageMain,
    Bottom,

  },
  
};
</script>

<style >

</style>
