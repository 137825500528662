<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-28 13:57:04
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\PageMain\Bottom\Info.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="site-info">
    <div class="containersss clearfix">
      <div class="title_text">
        <p>多平台支持，金融级安全保障</p>
      </div>
      <div class="seation">
        <div class="seation_img">
          <div>
            <img src="../../../assets/imgs/TKK/iso.png" width="42px" height="42px">
          </div>
          <div>
            <p>ISO认证</p>
          </div>
        </div>

        <div class="seation_img">
          <div>
            <img src="../../../assets/imgs/TKK/anquan.png" width="42px" height="42px">
          </div>
          <div>
            <p>网络安全等级保护</p>
          </div>
        </div>

        <div class="seation_img">
          <div>
            <img src="../../../assets/imgs/TKK/ali.png" width="42px" height="42px">
          </div>
          <div>
            <p>阿里云安全支持</p>
          </div>
        </div>

        <div class="seation_img">
          <div>
            <img src="../../../assets/imgs/TKK/shuju.png" width="42px" height="42px">
          </div>
          <div>
            <p>数据隔离</p>
          </div>
        </div>

        <div class="seation_img">
          <div>
            <img src="../../../assets/imgs/TKK/yinghang.png" width="42px" height="42px">
          </div>
          <div>
            <p>银行级网络加密通信</p>
          </div>
        </div>
      </div>
     



    </div>
     <div class="footer_text">
        <p>Copyright © 2022 图哐哐 All right reserved.</p>
        <p style="margin-left:10px;"><a href="https://beian.miit.gov.cn/#/Integrated/index" >粤ICP备2022044711号</a></p>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.site-info {
  // padding: 30px 0;
  font-size: 12px;
  background: rgb(65, 65, 79);
  .containersss{
    // position: relative;
    width: 70%;
    margin-right: auto;
    margin-left: auto;


  }
}
.site-info .slogan {
  clear: both;
  margin: 20px auto 0;
  width: 100%;
  height: 19px;
  background: url(https://cdn.cnbj1.fds.api.mi-img.com/staticsfile/global/slogan2020.png) no-repeat center 0;
}
.title_text{
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  height: 50px;
  // line-height: 100px;
}
.seation{
  display: flex;
  justify-content: space-between;
 width: 80%;
 margin-left: auto;
 margin-right: auto;
  .seation_img{
    text-align: center;
    transition: 0.5s;
  }
  .seation_img:hover{
     transform:scale(1.3,1.3);
  }
  p{
    margin-top: 10px;
    
    color: #ffffff;
    font-size: 10px;
  }
}
.footer_text{
  display: flex;
 border-top: 1px solid #4a505a;
margin-top: 30px;
  justify-content: center;
  height: 70px;
  line-height: 70px;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
}
 a:hover{
  color: rgb(43, 95, 255);
  border-bottom: 1px solid rgb(43, 95, 255);
}
</style>