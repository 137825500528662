<template>
    <div class="home-tool-bar ">
     
      <!-- <a href="" class="item1 item-image">
        <div class="icon">
          <img src="../assets/imgs/TKK/03.png" alt="" class="icon-img">
          <img src="../assets/imgs/TKK/03.png" alt="" class="icon-img">
        </div>
        <span class="text">手机APP</span   @click="returnTop">
      </a> -->
      <a href="" class="item1 backtop" @click="returnTop">
            <p class="text" >合作咨询</p>
        <div class="icon">
          <img src="../assets/imgs/TKK/03.png" alt="" class="static">
          <!-- <img src="../assets/imgs/TKK/03.png" alt="" class="hover"> -->
        </div>
        <p class="text">扫一扫<br>添加负责人微信</p>
        <!-- <span class="text">添加负责人微信</span> -->
      </a>
    </div>
</template>

<script>
export default {
  data(){
    return{
      isShow : false
    }
  },
  created(){
    const html = document.documentElement;
    document.addEventListener('scroll', (e) => {
      let scrolled = html.scrollTop / (html.scrollHeight - html.clientHeight)
      let scrollTop = document.querySelector('.backtop')
      if(scrolled>0.3){
        scrollTop.style.visibility = 'visible'
      }else{
        scrollTop.style.visibility = 'hidden'
      }
    })
  },
  methods:{
    returnTop(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
}
</script>

<style lang="less" scoped>
.home-tool-bar{
  position: fixed;
  bottom: 70px;
  right: 0;
  z-index: 99;
  .item1{
    position: relative;
    display: block;
    width: 9vw;
    height: 25vh;
    margin-top: -1px;
    background-color: #e1e1e1;
    border: 1px solid #e1e1e1;
    text-align: center;
    border-radius: 5%;
    .icon{
      position: relative;
      width: 9vw;
      height: 12vh;
      // margin: 0 auto 8px;
      padding-top: 2vh;
      .img{
        // position: absolute;
        // bottom: 0;
        // left: 0;
        width: 80px;
        height: 80px;
        // -webkit-transition: opacity .3s;
        // transition: opacity .3s;
      }
      .static{
        width: 5vw;
        height: 9vh;
        transform:scale(1.3,1.3);
      }
      .hover{
        transform:scale(1.3,1.3);
      }
    }
    .text{
      color: #3f79f8;
      -webkit-transition: color .3s;
      transition: color .3s;
      margin-top: 1vh;
      font-size: 0.5rem;
    }
    .pop-content{
      position: absolute;
      left: 0;
      top: 0;
      padding: 14px;
      background: #fff;
      border: 1px solid #f5f5f5;
      -webkit-transition: opacity .3s;
      transition: opacity .3s;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 0;
      visibility: hidden;
      img{
        display: block;
        width: 100px;
        height: 100px;
        margin: 6px auto;
      }
      .desc{
        display: block;
        width: 82px;
        margin: 14px auto 0;
        color: #757575;
        text-align: center;
      }
    }
    .pop-content::before{
      content: "";
      position: absolute;
      top: 21%;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      overflow: hidden;
      right: -19px;
      border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) #f5f5f5;
      z-index: 1;
    }
    .pop-content::after{
      content: "";
      position: absolute;
      top: 21%;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      overflow: hidden;
      right: -18px;
      border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) #fff;
      z-index: 2;
    }
  }
  .item1:hover .text{
    color: #ff6700;
  }
  // .item1:hover .static{
  //   opacity: 0;
  // }
  // .item1:hover .hover{
  //   opacity: 1;
  // }
  .item-image:hover .pop-content{
    visibility: visible;
    opacity: 1;

    transform: translate3d(-110%,0,0);
  }
    
}
.backtop{
  margin-top: 14px !important;
  border-top: 1px solid #f5f5f5 !important;
  visibility: hidden;
}

</style>