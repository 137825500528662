export {AABB3}

	class AABB3
	{
		constructor()
		{
			this.state= {
					maxPosition:[-Number.MAX_VALUE,-Number.MAX_VALUE,-Number.MAX_VALUE],
					minPosition:[Number.MAX_VALUE,Number.MAX_VALUE,Number.MAX_VALUE],
				}

		}

		addPoint_array3(array)
		{
			if(array[0]>this.state.maxPosition[0])
				this.state.maxPosition[0]=array[0];
			if(array[0]<this.state.minPosition[0])
				this.state.minPosition[0]=array[0];

			if(array[1]>this.state.maxPosition[1])
				this.state.maxPosition[1]=array[1];
			 if(array[1]<this.state.minPosition[1])
				this.state.minPosition[1]=array[1];

			if(array[2]>this.state.maxPosition[2])
				this.state.maxPosition[2]=array[2];
			if(array[2]<this.state.minPosition[2])
				this.state.minPosition[2]=array[2];
			array=null;
		}
		addPoint_array3_xyz(x,y,z)
		{
			if(x>this.state.maxPosition[0])
				this.state.maxPosition[0]=x;
			if(x<this.state.minPosition[0])
				this.state.minPosition[0]=x;
			
			if(y>this.state.maxPosition[1])
				this.state.maxPosition[1]=y;
			if(y<this.state.minPosition[1])
				this.state.minPosition[1]=y;
			
			if(z>this.state.maxPosition[2])
				this.state.maxPosition[2]=z;
			if(z<this.state.minPosition[2])
				this.state.minPosition[2]=z;
		}
		
		addPoint3_vec3(vector3)
		{
			let array=vector3.data;
			if(array[0]>this.state.maxPosition[0])
				this.state.maxPosition[0]=array[0];
			if(array[0]<this.state.minPosition[0])
				this.state.minPosition[0]=array[0];

			if(array[1]>this.state.maxPosition[1])
				this.state.maxPosition[1]=array[1];
			if(array[1]<this.state.minPosition[1])
				this.state.minPosition[1]=array[1];

			if(array[2]>this.state.maxPosition[2])
				this.state.maxPosition[2]=array[2];
			if(array[2]<this.state.minPosition[2])
				this.state.minPosition[2]=array[2];
			array=null;
			vector3=null;
		}

		getMaxPosition()
		{
			return this.state.maxPosition;
		}
		getMinPosition()
		{
			return this.state.minPosition;
		}
		getCenterPosition()
		{
			let result=[];
			for(let i=0;i<3;i++)
			{
				result.push((this.state.maxPosition[i]+this.state.minPosition[i])/2.0);
			}
			return result;
		}

		getSize()
		{
			return [this.state.maxPosition[0]-this.state.minPosition[0],this.state.maxPosition[1]-this.state.minPosition[1],this.state.maxPosition[2]-this.state.minPosition[2]];
		}

		getSize_Y()
		{
			return this.state.maxPosition[1]-this.state.minPosition[1];
		}

		clear()
		{
			this.state.maxPosition=[-Number.MAX_VALUE,-Number.MAX_VALUE,-Number.MAX_VALUE];
			this.state.minPosition=[Number.MAX_VALUE,Number.MAX_VALUE,Number.MAX_VALUE];
		}

	}

