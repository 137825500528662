<template>
  <div class="site-footer">
    <div class="container">
      <div class="li_left">
        <div class="left_one">
          <div class="left_title">
            <p>产品</p>
          </div>
          <div class="left_li">
            <ul>
              <li @click="$router.replace({path:'/Design/tkk3D'})">图哐哐APP</li>
              <li>ISOabc</li>
            </ul>
          </div>
        </div>

        <div class="left_one">
          <div class="left_title">
            <p>关于</p>
          </div>
          <div class="left_li">
            <ul>
              <li @click="$router.replace({path:'/Brief/jianjie'})">
                公司简介
                <!-- <router-link to=""></router-link>  $router.replace({path:'/Brief/index'})-->
              </li>
              <li @click="$router.replace({path:'/recruit/recruit'})">人才招聘</li>
              <li @click="$router.replace({path:'/agreement/yinsi'})">隐私政策</li>
              <li @click="$router.replace({path:'/agreement/xieyi'})">用户协议</li>
            </ul>
          </div>
        </div>

        <div class="left_one">
          <div class="left_title">
            <p>合作</p>
          </div>
          <div class="left_li">
            <ul>
              <li
                @click="$router.replace({path:'/Design/zhaopin'})"
              >设计师&emsp;&emsp;/&emsp;&emsp;工程师入驻</li>
              <li
                @click="$router.replace({path:'/Design/zhaopin'})"
              >企业&emsp;&emsp;/&emsp;&emsp;工厂&emsp;&emsp;/&emsp;&emsp;机构入驻</li>
              <li>3D网站&emsp;&emsp;/&emsp;&emsp;3D电商</li>
              <li>3D教育&emsp;&emsp;/&emsp;&emsp;3D医学</li>
              <li @click="$router.replace({path:'/Lunbo/ceshi'})">元宇宙&emsp;&emsp;/&emsp;&emsp;NFT</li>
              <li @click="$router.replace({path:'/ceshi/ces'})">MES&emsp;&emsp;/&emsp;&emsp;PLM</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="right_one">
        <div class="right_title">
          <div style="margin-top:10px;">
            <img src="../../../assets/imgs/logo (2).svg" width="65px" height="65px" />
          </div>
          <div style="margin-left:10px">
            <p>小秤砣</p>
          </div>
        </div>
        <div class="right_li">
          <ul>
            <li>
              <div>
                <img src="../../../assets/imgs/TKK/phone.png" width="16px" height="16px" />
              </div>
              <div style="margin-left:10px;">
                <p>+86 0755-8690 2867</p>
              </div>
              <div style="margin-left:10px;font-size:12px;color:#d6d6d6;">
                <p>9：00-18：00</p>
              </div>
            </li>
            <li>
              <div>
                <img src="../../../assets/imgs/TKK/dizhi.png" width="16px" height="16px" />
              </div>
              <div style="margin-left:10px">
                <p>深圳市 光明区 中粮云景广场第1栋1305</p>
              </div>
            </li>
            <li>
              <div>
                <img src="../../../assets/imgs/TKK/youjian.png" width="16px" height="16px" />
              </div>
              <div style="margin-left:10px">
                <p>lu@smallweight.com</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="right_img">
          <div class="right_img_one">
            <img src="../../../assets/imgs/TKK/02.jpg" width="102px" height="102px" />
            <p>关注微信公众号</p>
          </div>
          <div class="right_img_one" style="margin-left:30px">
            <img src="../../../assets/imgs/TKK/yonghu6.png" width="102px" height="102px" />
            <p>官方客服微信</p>
          </div>
        </div>
      </div>
    </div>
    <div class="xian"></div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      isOverWX: false
    };
  },
  methods: {
    showImg() {
      this.isOverWX = true;
    },
    hideImg() {
      this.isOverWX = false;
    }
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0px;
  padding: 0px;
}
.site-footer {
  background-color: rgb(65, 65, 79);
  color: #ffffff;
  padding-bottom: 5vh;
}
.xian {
  margin-top: 10vh;
  width: 100%;
  border-bottom: 1px solid #4a505a;
}
li {
  list-style-type: none;
}
.container {
  padding-top: 40px;
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  // justify-content: space-between;
  .li_left {
    width: 40%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .left_title {
      font-size: 20px;
      color: rgb(255, 106, 0);
    }
    li {
      margin-top: 30px;
      cursor: pointer;
      color: #ffffff;
      font-size: 14px;
    }
    li:hover {
      color: rgba(91, 132, 255, 1);
      cursor: pointer;
    }
  }
  .right_one {
    .right_title {
      display: flex;

      font-size: 36px;
      align-items: center;
    }
    li {
      display: flex;
      align-items: center;

      margin-top: 15px;
    }
  }
  .right_img {
    display: flex;
    margin-top: 20px;
    p {
      margin-top: 5px;
      text-align: center;
      font-size: 12px;
    }
  }
}
.right_img_one {
  transition: 0.5s;
}
.right_img_one:hover {
  transform: scale(1.2, 1.2);
}
</style>