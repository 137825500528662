<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-08-18 18:09:14
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\Lunbo\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home-hero-container container">
    <div class="home-hero" ref="Container">
      <!-- <div class="row-texts">3D   View</div> -->
      <canvas class="row-canvas" id="shower"></canvas>
      <div class="row-pro" v-if="modelPro">
        <!-- <el-progress :text-inside="false" :show-text="false"  :percentage="val" type="circle"></el-progress> -->
        <div class="row-pro-text">加载中...</div>
      </div>

      <div id="modelImageRow" class="row-image"></div>
      <div class="row-zong">
        <!--爆炸图  -->
        <div class="row-bao">
          <div>
            <el-popover placement="right" width="220" trigger="click">
              <el-form>
                <el-form-item>
                  <input type="range" min="0.0" max="2.0" step="0.1" @input="setExplodeScalar" />
                </el-form-item>
                <el-form-item>
                  <el-checkbox id="X_explode" @change="enableExplodeDirectionX" :checked="true">X轴</el-checkbox>
                  <el-checkbox id="Y_explode" @change="enableExplodeDirectionY" :checked="true">Y轴</el-checkbox>
                  <el-checkbox id="Z_explode" @change="enableExplodeDirectionZ" :checked="true">Z轴</el-checkbox>
                </el-form-item>
              </el-form>
              <el-button slot="reference" icon="el-icon-rank" size="medium" >爆炸</el-button>
            </el-popover>
          </div>
        </div>

        <!--旋转  -->
        <div class="row-bao" style="margin-top:20px">
          <div>
            <el-popover placement="right" width="300" trigger="click">
              <el-form>
                <el-form-item>
                  <el-button type="primary" @click="slowRotateSpeed" icon="el-icon-minus">逆时针</el-button>
                  <el-button type="primary" @click="switchAutoRotate" icon="el-icon-video-pause"></el-button>
                  <el-button type="primary" @click="fastRotateSpeed" icon="el-icon-plus">顺时针</el-button>
                </el-form-item>
                
                <el-form-item>
                  <el-button id="X_autoRotate" class="autoRotateAxesSelector" @click="switchAutoRotateAxes('X')" :type="rotateAxesButtonSelect.X">X轴</el-button>
                  <el-button id="Y_autoRotate" class="autoRotateAxesSelector" @click="switchAutoRotateAxes('Y')" :type="rotateAxesButtonSelect.Y">Y轴</el-button>
                  <el-button id="Z_autoRotate" class="autoRotateAxesSelector" @click="switchAutoRotateAxes('Z')" :type="rotateAxesButtonSelect.Z">Z轴</el-button>

                </el-form-item>
              </el-form>
              <el-button slot="reference" icon="el-icon-refresh" size="medium" >旋转</el-button>
            </el-popover>
          </div>
        </div>
        <div class="row-table" style="margin-top:20px">
          <div>
            <el-popover placement="right" width="170" popper-class="popover" trigger="click">
              <el-button type="primary" icon="el-icon-plus"  @click="operatorCamera('moveFront')" size="small" >放大</el-button>
                  <el-button type="primary" icon="el-icon-minus"  @click="operatorCamera('moveBack')" size="small" >缩小</el-button>
              <!-- <el-form>
                <el-form-item>
                  <el-button type="primary" icon="el-icon-plus"  @click="operatorCamera('moveFront')" size="small" >放大</el-button>
                  <el-button type="primary" icon="el-icon-minus"  @click="operatorCamera('moveBack')" size="small" >缩小</el-button>
                </el-form-item>
              </el-form> -->
              <el-button slot="reference" icon="el-icon-plus" size="medium" >缩放</el-button>
            </el-popover>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as fs from "fs";
import { Input } from "element-ui";

import { Util, tkk3DEngine, Matrix } from "../../util/tkk3DEngine/importBundle";
const cityOptions = ['X轴', 'Y轴', 'Z轴'];
export default {
  name: "index",
  data() {
    return {
      modelPro: false,
      renderEngine: null,
      modelFiles: [
        {
          previewImageKey: "2022072018075751752471.png",
          modelKey: "2022072017194425028912.obj",
          is_binary: false,
          matrix_model_rotate: Matrix.getRotate_angle_X(180)
        },
        {
          previewImageKey: "2022072018080180696829.png",
          modelKey: "2022072017195484545592.obj",
          is_binary: false,
          matrix_model_rotate: Matrix.getIdentity(4, 4)
        },
        {
          previewImageKey: "2022072018081046182116.png",
          modelKey: "2022072017201005177578.obj",
          is_binary: false,
          matrix_model_rotate: Matrix.getRotate_angle_Y(0)
        },
        {
          previewImageKey: "2022072018081552347126.png",
          modelKey: "2022072915242298368135.obj",
          is_binary: false,
          matrix_model_rotate: Matrix.multiply(
            Matrix.getRotate_angle_Y(-90),
            Matrix.getRotate_angle_X(-90)
          )
        },
        {
          previewImageKey: "2022072915155238301721.png",
          modelKey: "2022072017205327436243.stl",
          is_binary: true,
          matrix_model_rotate: Matrix.multiply(
            Matrix.getRotate_angle_Z(90),
            Matrix.getRotate_angle_Y(90)
          )
        },
        {
          previewImageKey: "2022072018102669073546.png",
          modelKey: "2022072017210776958780.obj",
          is_binary: false,
          matrix_model_rotate: Matrix.multiply(
            Matrix.getRotate_angle_Y(90),
            Matrix.getRotate_angle_X(-90)
          )
        },
        {
          previewImageKey: "2022072018103072417921.png",
          modelKey: "2022072017212453334760.obj",
          is_binary: false,
          matrix_model_rotate: Matrix.multiply(
            Matrix.getRotate_angle_Y(0),
            Matrix.getRotate_angle_X(-90)
          )
        },
        {
          previewImageKey: "2022072018103424989761.png",
          modelKey: "2022072017214242887886.obj",
          is_binary: false,
          matrix_model_rotate: Matrix.multiply(
            Matrix.getRotate_angle_Y(90),
            Matrix.getRotate_angle_X(-90)
          )
        }
      ],
      url: "192.168.110.230:8888",
      loadModule: {
        pako: null,
        modelXhr: new XMLHttpRequest()
      },
      loadStatus: {
        prograss: 0,
        ratio: 1.0,
        stop: false,
        callBackFunction: null
      },
      val: "0",
      times: "",
      explodeDirection: {
        //控制爆炸图的x,y,z方向
        X: true,
        Y: true,
        Z: true
      },
      rotateAxesButtonSelect:
      {
        X:"",
        Y:"primary",
        Z:"",
      },

      checkedCities: ['Y'],
        cities: cityOptions
    };
  },
  mounted() {
    let that = this;
    console.log(" start mounted hook");

    let modelImageRow = document.getElementById("modelImageRow");
    for (let i = 0; i < this.modelFiles.length; i++) {
      let imageContainerDom = document.createElement("div");
      imageContainerDom.onclick = function() {
        that.loadModel(i);
      };

      imageContainerDom.style = "width:100px;height:100px;";
      let imageDom = document.createElement("img");
      imageDom.style =
        "width:100px; height:100px; background:#b0bed6;border-radius: 10px;";
      imageDom.src =
        "https://xct-services-api.smallweight.com/api/metaverse/images?key=" +
        this.modelFiles[i].previewImageKey;
      imageContainerDom.appendChild(imageDom);
      modelImageRow.appendChild(imageContainerDom);
    }
    this.loadModule.pako = require("pako");
    const pako = this.loadModule.pako;

    // add window resize event
    document.oncontextmenu = function() {
      return false;
    };
    window.addEventListener(
      "resize",
      function(event) {
        let dom = document.getElementById("shower");
        that.renderEngine.state.camera.state.WDH =
          (dom.offsetWidth + 0.001) / (dom.offsetHeight + 0.001);
        that.renderEngine.state.matrixs.project.data = that.renderEngine.state.camera.getMatrix4X4_orthogonal().data;
        console.log("new WDH ", that.renderEngine.state.camera.state.WDH);
      },
      true
    );

    // load event

    this.loadStatus.callBackFunction = function() {
      that.val = " " + Math.round(that.loadStatus.prograss * 100);
      that.modelPro = true;
      that.times = 5;
      let timer = setInterval(() => {
        that.times--;
        if (that.times < 1) {
          clearInterval(timer);
          that.times = 0;
          if (that.val == 100) {
            that.modelPro = false;
          }
        }
      }, 1000);

      // this.val = status
    };

    // load engine
    if (this.renderEngine == null) {
      this.loadEngine();
    }

    this.loadModel(0);
    console.log("end mounted hook");
  },
  methods: {
    loadEngine() {
      let that = this;

      this.renderEngine = new tkk3DEngine();
      let canvas = document.getElementById("shower");
      canvas.width = 1600;
      canvas.height = 1000;
      let fr = document.createElement("input");
      fr.type = "file";
      fr.onchange = function(result) {
        that.renderEngine.loadMeshFromFile(fr.files[0]);
      };
      canvas.width = 1920;
      canvas.height = 1080;
      //document.getElementsByClassName("row")[0].appendChild(fr)
      // document.getElementById("Container").append('bao');
      this.renderEngine.setAttribute("renderPass", [
        "defaultBackground_Website",
        "defaultModel_Website"
      ]);
      this.renderEngine.setAttribute("targetCanvas", canvas);
      this.renderEngine.startRenderLoop();
    },
    // load the model
    loadModel(index) {
      console.log("start request model ", this.modelFiles[index].modelKey);
      let that = this;
      this.loadStatus.prograss = 0;
      this.loadStatus.ratio = 1.0;
      this.loadStatus.callBackFunction();
      this.renderEngine.state.renderPass = ["defaultBackground_Website"];
      this.renderEngine.state.webglRenderer.renderWithFunction(
        this.renderEngine.render
      );

      const pako = this.loadModule.pako;
      let xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        "https://xct-services-api.smallweight.com/api/get/code?key=" +
          this.modelFiles[index].modelKey
      );
      xhr.responseType = "arraybuffer";
      let format = Util.getSuffixFromFileName(this.modelFiles[index].modelKey);
      xhr.onreadystatechange = function() {
        if (xhr.readyState == 4) {
          //监听readyState状态
          if (xhr.status == 200 || xhr.status == 0) {
            //监听HTTP状态码
            that.loadStatus.ratio = 0.6;
            that.loadStatus.prograss = 0.3;
            if (that.loadStatus.callBackFunction != null)
              that.loadStatus.callBackFunction();
            let arg = {};
            arg.filename = that.modelFiles[index].modelKey;
            arg.isBinary = that.modelFiles[index].is_binary;
            arg.loadState = that.loadStatus;
            switch (format) {
              case "obj":
                {
                  arg.meshSource = pako.inflate(xhr.response, { to: "string" });
                  that.renderEngine.setAttribute("model", arg);
                }
                break;
              case "stl":
                {
                  if (that.modelFiles[index].is_binary) {
                    arg.meshSource = pako.inflate(xhr.response).buffer;
                  } else {
                    arg.meshSource = pako.inflate(xhr.response, {
                      to: "string"
                    });
                  }
                  that.renderEngine.setAttribute("model", arg);
                }
                break;
              //case "stp":{}break;
              default:
                {
                }
                break;
            }

            arg = null;
            that.renderEngine.state.mesh.data.matrice.model_rotate.data =
              that.modelFiles[index].matrix_model_rotate.data;
            that.renderEngine.state.mesh.data.matrice.world_rotate.data = Matrix.multiply(
              Matrix.getRotate_angle_Z(-30),
              Matrix.getRotate_angle_Y(-30)
            ).data;
            that.renderEngine.setAttribute("renderPass", [
              "defaultBackground_Website",
              "defaultModel_Website"
            ]);
            that.loadStatus.ratio = 1;
            that.loadStatus.prograss = 1;
            that.loadStatus.callBackFunction();
          }
        }
      };
      xhr.onprogress = function(event) {
        console.log(event);
        if (event.lengthComputable) {
          that.loadStatus.prograss =
            (that.loadStatus.ratio * Math.round(event.loaded / 300)) /
            Math.round(event.total / 300);
          that.loadStatus.callBackFunction();
        }
      };

      xhr.send();

      //this.renderEngine.loadMeshFromFile(this.modelFiles[index]);
      console.log("end request model ", this.modelFiles[index].modelKey);
    },

    operatorCamera(operator = "none") {
      if (typeof operator != "string") return;

      switch (operator) {
        case "moveFront":
          {
            this.renderEngine.setAttribute("move", "front");
          }
          break;
        case "moveBack":
          {
            this.renderEngine.setAttribute("move", "back");
          }
          break;
        default:
          {
          }
          break;
      }
    },
    //爆炸图
    setExplodeScalar(event) {
      this.renderEngine.setAttribute("explodeScalar", {
        x: this.explodeDirection.X ? event.target.value : null,
        y: this.explodeDirection.Y ? event.target.value : null,
        z: this.explodeDirection.Z ? event.target.value : null
      });
    },
    enableExplodeDirectionX(event) {
      console.log(this.explodeDirection.X, "dadada");
      this.explodeDirection.X = event;
      // this.explodeDirection
      console.log(this.explodeDirection);
    },
    enableExplodeDirectionY(event) {
      console.log(this.explodeDirection.X, "dadada");
      this.explodeDirection.Y = event;
      // this.explodeDirection
      console.log(this.explodeDirection);
    },
    enableExplodeDirectionZ(event) {
      console.log(this.explodeDirection.X, "dadada");
      this.explodeDirection.Z = event;
      // this.explodeDirection
      console.log(this.explodeDirection);
    },
    //旋转减速
    slowRotateSpeed(event) {
      console.log("123");
      this.renderEngine.setAttribute(
        "autoRotateSpeed",
        this.renderEngine.getAttribute("autoRotateSpeed") - 10
      );
    },
    //旋转加速
    fastRotateSpeed(event) {
      this.renderEngine.setAttribute(
        "autoRotateSpeed",
        this.renderEngine.getAttribute("autoRotateSpeed") + 10
      );
    },
    //旋转开始暂停
    switchAutoRotate(enable) {
      this.renderEngine.setAttribute(
        "enableAutoRotate",
        !this.renderEngine.getAttribute("enableAutoRotate")
      );
    },
    //旋转的X，Y，Z轴
    switchAutoRotateAxes(event){

      // event.target.checked=true;
      let axes=[0,0,0];
      switch (event)
      {
        case 'X': {
          this.rotateAxesButtonSelect.X="primary";
          this.rotateAxesButtonSelect.Y="";
          this.rotateAxesButtonSelect.Z="";
          axes[0]=1;
        } break;
        case 'Y': {
          this.rotateAxesButtonSelect.X="";
          this.rotateAxesButtonSelect.Y="primary";
          this.rotateAxesButtonSelect.Z="";
          axes[1]=1;
        } break;
        case 'Z': {
          this.rotateAxesButtonSelect.X="";
          this.rotateAxesButtonSelect.Y="";
          this.rotateAxesButtonSelect.Z="primary";
          axes[2]=1;
        } break;
      }
      this.renderEngine.setAttribute("autoRotateAxes",axes)
    }
  }
};
</script>

<style lang="less" scoped>
.popover{
  height: 43px;
}
.home-hero-container {
  position: relative;
  z-index: 10;

  .home-hero {
    height: 100vh;
  }
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.row-canvas {
  width: 100%;
  background-color: aqua;
  height: 100vh;
  // border-radius: 10px;
}
.row-texts {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 30px;
  width: 100%;
  color: rgba(91, 132, 255, 1);
  font-weight: 700;
  text-align: center;
  z-index: 10;
  // background: #f5f5f5;
}
.row-image {
  position: absolute;
  top: 85vh;
  left: 20vw;
  width: 60%;

  display: flex;
  justify-content: space-between;
  // background: #000;
}
.modelImage {
  width: 100px;
  height: 100px;
  background-color: green;
  // border: 1px black solid;
}
.row-zong {
  width: 3%;
 
  // justify-content: space-between;
  position: relative;
  top: -85vh;
  left: 90vw;
}
.row-table {
  // display: flex;
  // border: 1px solid #000;
}

li {
  list-style-type: none;
}
.row-pro {
  width: 10%;
  position: relative;
  top: -45vh;
  left: 45vw;
}
.row-pro-text {
  color: #97a3b8;
  font-size: 20px;
  position: relative;
  top: -10vh;
  left: 2vw;
}
.row-table-left {
  width: 100%;
  // width:100px;
  // height: 100px;
  // border: 5px solid black;
  // z-index: 10;
}

</style>