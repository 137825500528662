<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-06-29 14:38:06
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\PageMain\XiaoMiSG\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home-flashsale xm-plain-box">
    <Head />
    <Content />
    <Heads />
  </div>
</template>

<script>
import Head from './Head'
import Content from './Content'
import Heads from './Heads'

export default {
  name: 'XiaoMiSG',
  components: {
    Head,
    Content,
    Heads
  }
}
</script>

<style>
.home-flashsale {
  margin-bottom: 22px;
  position: relative;
}
.home-banner-box {
  width: 100%;
  height: 120px;
  margin: 22px 0;
  overflow: hidden;
}
</style>