<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-06-28 15:20:45
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\PageMain\Shouji\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home-brick-box">

    <Bottom />
 
  </div>
</template>

<script>

import Bottom from './Bottom'

export default {
  name: 'Shouji',
  components:{
    Bottom
  }
}
</script>

<style>
.home-brick-box {
  margin-bottom: 8px;
  
}

</style>