<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-13 19:00:08
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\header\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="header">
    <SiteTopbar />
  
  </div>
</template>

<script>
import SiteTopbar from '@/components/header/SiteTopbar'



export default {
  name: 'index',
  components:{
    SiteTopbar,
    
  }
}
</script>

<style>
/* .header{
  height: 60px;
} */
</style>