<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-13 18:52:12
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\PageMain\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="page-main home-main">
    <div class="container">
      <XiaoMiSG />
      


     
    </div>
    <Shouji />
    <div  class="container">
 <ShiPing />
      <Cebian/>
    </div>
  </div>
</template>

<script>
import XiaoMiSG from "@/components/PageMain/XiaoMiSG";
import Shouji from "@/components/PageMain/Shouji";

import ShiPing from "@/components/PageMain/ShiPing";
import Cebian from '@/components/Cebian';

export default {
  name: "PageMain",
  components: {
    XiaoMiSG,
    Shouji,

    ShiPing,
     Cebian
  },
};
</script>

<style>
.home-main {
  /* padding-top: 4px;
  padding-bottom: 12px; */

}
.page-main {

}
.container {
  width: 1226px;
  margin-right: auto;
  margin-left: auto;
}
</style>