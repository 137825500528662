<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-13 10:03:07
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\PageMain\XiaoMiSG\Content.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="box-bd clearfix">
    <div class="row">
      <div class="row-o">
        <div class="row-model">
          <p style="color:rgba(255, 195, 0, 1);">模型上传+3D展示</p>
          <p>让产品设计所见即所得</p>
        </div>
        <div class="row-tabs">
            <div class='row-up'><p>模型上传</p></div>
            <div><p>3D展示</p></div>
        </div>
        <div class="row-text">
          <p>支持step(stp)、stl、part、obj、igs、set、cat、</p>
          <p>model、asm...等几十种CAD主流3D文档格式</p>
        </div>
      </div>
      <div class="rowimg">
        <img src="../../../assets/imgs/TKK/tuone.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Content',
  data() {
    return {
    }
  },
  created(){

  },
  mounted(){
    
  },
  methods:{
  
  }
};
</script>

<style lang="less" scoped>
.row {

  display: flex;
justify-content: center;
  margin-top: 5vw;
  width: 100%;
  height: 70vh;

  .row-o{
    width: 100%;

    position: relative;
    top: 10vw;
    left: 12vh;
    .row-model{
      font-size:38px ;
    }  
    .row-tabs{
      display: flex;
      text-align: center;

      div{
        
       margin-top: 20px;
        width: 100px;
        height: 48px;
        font-size: 20px;
        line-height: 48px;
        color: #ffffff;
        background: rgba(91, 132, 255, 1);
      }
      div:hover{
        color: rgba(91, 132, 255, 1);
        background: #ffffff;
        cursor: pointer;
        padding-bottom: 5px solid rgba(91, 132, 255, 1);
      }
      
    }
    .row-text{

        margin-top: 20px;
        color: rgba(69, 77, 89, 0.6);
        width: 100%;
        height: 68px;
        font-size: 20px;
      }
  }
  .rowimg img{
    
    width: 35vw;
  }
}

</style>
