<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-21 10:43:04
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\PageMain\XiaoMiSG\Head.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="box-hd">
    <div class="image">
      <div class="image_text">
        <p>工程师招募：画3D、玩模型、</p>
      </div>
      <div class="image_text" style="color:rgba(247, 228, 52, 1)">
        <p>元宇宙</p>
      </div>
      <div class="image_texts"  @click="$router.replace({path:'/Design/zhaopin'})">
         <el-button class="image_button" round  @click="$router.replace({path:'/Design/zhaopin'})">立即加入</el-button>
      </div>
    </div>
  </div>

</template>

<script>
export default {};
</script>

<style>
.image_button{
    color: #ffffff!important;
    font-size: 20px!important;
    background-image: linear-gradient(to bottom, rgb(255, 198, 138) , rgb(255, 107, 28))!important;

  }
</style>

<style  lang="less" scoped>
.xm-plain-box .box-hd {
  position: relative;
 height: 149px;
  -webkit-font-smoothing: antialiased;
  margin-top: 50px;
}
.xm-plain-box .box-hd .title {
  margin: 0;
  font-size: 22px;
  font-weight: 200;
  line-height: 58px;
  color: #333;
}
.image{
  width: 100%;
  height: 149px;
  background-image: url('../../../assets/imgs/TKK/zhaopai.png');
    background-repeat:no-repeat;
  background-size: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  .image_text{
      font-size: 60px;
      font-weight: 700;
      color: #ffffff;
      line-height: 149px;
  }
  .image_texts{
      position: relative;
      top: 55px;
      left: 40px;
  }



}

</style>