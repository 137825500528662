
export {Pipeline}

import {WebglRenderer,getGlobalWebgl} from "./WebglRenderer.js"

	class Pipeline
    {
        constructor() {
            this.state=
                {
                    shaderProgram:null,
                    vertexShader:null,
                    fragmentShader:null,
                    newShader:
                        {
                            vertexShader:null,
                            fragmentShader:null,
                        },
                    isCompiled:false,
                    gl:null,
                }

            this.state.gl=getGlobalWebgl().gl;
            let gl=this.state.gl;
			
            this.state.shaderProgram=gl.createProgram();
            this.state.vertexShader=gl.createShader(gl.VERTEX_SHADER);
            this.state.fragmentShader=gl.createShader(gl.FRAGMENT_SHADER);

        }

        loadVertexShaderFromString(vertString)
        {
            let gl=this.state.gl;
            let temShader=gl.createShader(gl.VERTEX_SHADER);
            gl.shaderSource(temShader,vertString);
            gl.compileShader(temShader);
            if(!gl.getShaderParameter(temShader,gl.COMPILE_STATUS))
            {
                console.debug(gl.getShaderInfoLog(temShader));
                return false;
            }
            this.state.newShader.vertexShader=temShader;
            return true;
        }
        loadFragmentShaderFromString(fragString)
        {
            let gl=this.state.gl;
            let temShader=gl.createShader(gl.FRAGMENT_SHADER);
            gl.shaderSource(temShader,fragString);
            gl.compileShader(temShader);
            if(!gl.getShaderParameter(temShader,gl.COMPILE_STATUS))
            {
                console.debug(gl.getShaderInfoLog(temShader));
                return false;
            }
            this.state.newShader.fragmentShader=temShader;
            return true;
        }
        compile()
        {
            let gl=this.state.gl;
            if(this.state.newShader.vertexShader==null||
                this.state.newShader.fragmentShader==null)
                return false;
            let newShaderProgram=gl.createProgram();
            gl.attachShader(newShaderProgram,this.state.newShader.vertexShader);
            gl.attachShader(newShaderProgram,this.state.newShader.fragmentShader);
            gl.linkProgram(newShaderProgram);
            if(!gl.getProgramParameter(newShaderProgram,gl.LINK_STATUS))
            {
                let info=gl.getProgramInfoLog(newShaderProgram);
                console.error("fail to compile shader program\n"+info);

                gl.deleteProgram(newShaderProgram);
                gl.deleteShader(this.state.newShader.vertexShader);
                gl.deleteShader(this.state.newShader.fragmentShader);
                this.state.newShader.vertexShader=null;
                this.state.newShader.fragmentShader=null;


                return false;
            }
            gl.deleteShader(this.state.vertexShader);
            gl.deleteShader(this.state.fragmentShader);
            gl.deleteProgram(this.state.shaderProgram);
            this.state.vertexShader=this.state.newShader.vertexShader;
            this.state.fragmentShader=this.state.newShader.fragmentShader;
            this.state.shaderProgram=newShaderProgram;
            this.state.newShader.vertexShader=null;
            this.state.newShader.fragmentShader=null;
            return true;
        }
        use()
        {
            let gl=this.state.gl;
            gl.useProgram(this.state.shaderProgram);
        }

        setUniformBlockBinding(name,binding)
        {
            let gl=this.state.gl;
            gl.uniformBlockBinding(this.state.shaderProgram,gl.getUniformBlockIndex(this.state.shaderProgram,name),binding);
        }
        setUniformMatrix4f(uniformName,matrix,isTranspose=false)
        {
            let gl=this.state.gl;
            let location=gl.getUniformLocation(this.state.shaderProgram,uniformName);
            gl.uniformMatrix4fv(location,isTranspose,matrix);
        }

        setUniformVec3f(uniformName,vec)
        {
            let gl=this.state.gl;
            let location=gl.getUniformLocation(this.state.shaderProgram,uniformName);
            gl.uniform3fv(location,new Float32Array(vec) );
        }

        setUniform1i(uniformName,data)
        {
            let gl=this.state.gl;
            let location=gl.getUniformLocation(this.state.shaderProgram,uniformName);
            gl.uniform1i(location,data);
        }

        setUniform1f(uniformName,data)
        {
            let gl=this.state.gl;
            let location=gl.getUniformLocation(this.state.shaderProgram,uniformName);
            gl.uniform1f(location,data);
        }


    }
