import {getGlobalWebgl} from "./WebglRenderer";

export {Shape,Mesh}

import {Vector,Matrix} from "./Math.js"
import {AABB3} from "./BoundBox.js"
import {StringParser} from "./StringParser.js"
/*
*       arg{
*           meshSource:string,
*              loadState:object
*           }
*
*
* */

    function parseStringToOBJ(arg)
    {
		if(arg.loadState==null)
		{
			arg.loadState={
				prograss:0,
				ratio:1,
				stop:false,
			}
		}else
		{
			if(arg.loadState.stop==null)
                arg.loadState.stop=false;
		}
        let startProgress=arg.loadState.prograss;

        let lines=arg.meshSource.split('\n');
        arg.meshSource=null;
        let parse=new  StringParser();
        let result=new Mesh();
        let eachFacePointStartIndex=0;
        let eachFacePointIndex=0;
        let temPointIndex=0;
        let temColor=new Vector([1,1,1,1]);
        result.data.format="obj";
        let colors=new Array();     // storage all color
        let positions=new Array();  // storage all position
        let normals=new Array();   // storage all normal
        let uvs=new Array();        // storage all uvs

        let currentPositions=[];
        let currentNormals=[];
        let currentUvs=[];
        let currentColors=[];
        let currentIds=[];
        let temId=0;
        let currentDisplacement=[];
        let temAABB=new AABB3();

        colors.push(temColor);
        let temShape=new Shape();
        for( let index=0 ; index<lines.length && arg.loadState.stop==false ; index++ )
        {
            parse.setContent(lines[index]);
            let fs=parse.getNextVisibleString();
            
            switch (fs)
            {
                case "v": {
                    let v=new Vector(3);
                    v.data[0]=parse.getNextFloat();
                    v.data[1]=parse.getNextFloat();
                    v.data[2]=parse.getNextFloat();
                    positions.push(v);
                    v=null;
                    break;
                }
                case "vt":{
                    let t=new  Vector(2);
                    t.data[0]=parse.getNextFloat();
                    t.data[1]=parse.getNextFloat();
                    uvs.push(t);
                    t=null;
                    break;
                }
                case "vn":{
                    let n=new  Vector(3);
                    n.data[0]=parse.getNextFloat();
                    n.data[1]=parse.getNextFloat();
                    n.data[2]=parse.getNextFloat();
                    normals.push(n);
                    n=null;
                }
                    break;
                case "vc":
                {
                    let c=new Vector(4);
                    c.data[0]=parse.getNextFloat();
                    c.data[1]=parse.getNextFloat();
                    c.data[2]=parse.getNextFloat();
                    c.data[3]=parse.getNextFloat();
                    colors.push(c);
                    c=null;
                    break;
                }
                case "g":
                {

                    if(currentPositions.length>0)
                    {
                        temId+=1;
                        temShape.data.boundBox.addPoint_array3(temAABB.getMinPosition());
                        temShape.data.boundBox.addPoint_array3(temAABB.getMaxPosition());
                        console.log(temAABB);
                        let center=temAABB.getCenterPosition();
                        currentDisplacement.push(center[0],center[1],center[2],1.0);
                        temAABB.clear();
                        center=null;
                    }

                }break;
                case "f":
                {
                    eachFacePointIndex=0;
                    let temVec;

                    while(parse.hasNextUInt())
                    {
                        if(eachFacePointIndex>2)
                        {
                            // push the first

                            temPointIndex=currentPositions.length-3*eachFacePointIndex;
                            currentPositions.push(currentPositions[temPointIndex],currentPositions[temPointIndex+1],currentPositions[temPointIndex+2]);

                            if(currentUvs.length)
                            {
                                temPointIndex=currentUvs.length-2*eachFacePointIndex;
                                currentUvs.push(currentUvs[temPointIndex],currentUvs[temPointIndex+1]);
                            }
                            if(currentNormals.length)
                            {
                                temPointIndex=currentNormals.length-3*eachFacePointIndex;
                                currentNormals.push(currentNormals[temPointIndex],currentNormals[temPointIndex+1],currentNormals[temPointIndex+2]);
                            }
                            if(currentColors.length)
                            {
                                temPointIndex=currentColors.length-4*eachFacePointIndex;
                                currentColors.push(currentColors[temPointIndex],currentColors[temPointIndex+1],currentColors[temPointIndex+2],currentColors[temPointIndex+3]);
                            }
                            currentIds.push(temId);
                            // push the last

                            temPointIndex=currentPositions.length-6;
                            currentPositions.push(currentPositions[temPointIndex],currentPositions[temPointIndex+1],currentPositions[temPointIndex+2]);

                            if(currentUvs.length)
                            {
                                temPointIndex=currentUvs.length-4;
                                currentUvs.push(currentUvs[temPointIndex],currentUvs[temPointIndex+1]);
                            }
                            if(currentNormals.length)
                            {
                                temPointIndex=currentNormals.length-6;
                                currentNormals.push(currentNormals[temPointIndex],currentNormals[temPointIndex+1],currentNormals[temPointIndex+2]);
                            }
                            if(currentColors.length)
                            {
                                temPointIndex=currentColors.length-8;
                                currentColors.push(currentColors[temPointIndex],currentColors[temPointIndex+1],currentColors[temPointIndex+2],currentColors[temPointIndex+3]);
                            }
                            currentIds.push(temId);

                        }
                        let index;
                        let eachUnit= parse.getNextVisibleString().split('/');
                        // get the position index
                        index=parseInt(eachUnit[0])-1;
                        temVec=positions[index];

                        temAABB.addPoint3_vec3(temVec)
                        //console.log(temAABB.state.maxPosition," ",temAABB.state.minPosition," ",temVec.data);
                        currentPositions.push(temVec.data[0],temVec.data[1],temVec.data[2]);temVec=null;

                        currentIds.push(temId);
                        // get uvs
                        if(eachUnit[1].length>0)
                        {
                            index=parseInt(eachUnit[1]) - 1;
                            temVec=uvs[index];
                            currentUvs.push(temVec.data[0],temVec.data[1]);temVec=null;
                        }
                        // get normal
                        if(eachUnit.length>2&& eachUnit[2].length>0)
                        {
                            index=parseInt(eachUnit[2])-1;
                            temVec=normals[index];
                            currentNormals.push(temVec.data[0],temVec.data[1],temVec.data[2]);temVec=null;
                        }
                        // get the color
                        if(eachUnit.length>3&&eachUnit[3].length>0)
                        {
                            index=parseInt(eachUnit[3]);

                        }else
                        {
                            index=0;
                        }
                        temVec=colors[index];
                        currentColors.push(temVec.data[0],temVec.data[1],temVec.data[2],temVec.data[3]);temVec=null;
                        eachFacePointIndex+=1;
                    }
                    temVec=null;
                    break;
                }
                case "mtllib":break;
                case "usemtl":break;
            }

        }
        if(currentPositions.length>0)
        {
            temShape.data.boundBox.addPoint_array3(temAABB.getMinPosition());
            temShape.data.boundBox.addPoint_array3(temAABB.getMaxPosition());
            let center=temAABB.getCenterPosition();
            currentDisplacement.push(center[0],center[1],center[2],1);
            temAABB.clear();
            center=null;

            temShape.data.positions=new Float32Array(currentPositions);
            temShape.data.normals=new Float32Array(currentNormals);
            temShape.data.uvs=new Float32Array(currentUvs);
            temShape.data.colors=new Float32Array(currentColors);
            temShape.data.displacements=new Float32Array(currentDisplacement);
            temShape.data.ids=new Uint16Array(currentIds);

            result.data.shapes.push(temShape);
        }
        currentColors=null;
        currentNormals=null;
        currentPositions=null;
        currentUvs=null;
        currentIds=null;
        currentDisplacement=null;
        temAABB=null;

        parse=null;
        lines=null;
        temShape=null;
        currentPositions=null;
        currentNormals=null;
        currentColors=null;
        currentUvs=null;

        arg.loadState.prograss=startProgress+1.0*arg.loadState.ratio;
        return result;
    }

    /*
    *
    * arg{
    *   meshSource,
    *   loadState,
    * }
    *
    * */
    function parseStringToSTL(arg)
    {
        let sp=new StringParser();
        sp.setContent(arg.meshSource);
        arg.meshSource=null;
        sp.jumpToNextLine();
        let result=new Mesh();
        let defaultColor=new Vector([1,1,1,1]);
        let currentPositions=[];
        let currentNormals=[];
        let currentColors=[];
        let currentIds=[];
        result.data.format="stl";
        result.data.shapes.push(new Shape());


        let temNormal=new Vector(3);
        for(;sp.getNextVisibleString()=="facet";)
        {
            sp.getNextVisibleString();
            temNormal.data=[sp.getNextFloat(),sp.getNextFloat(),sp.getNextFloat()];
            sp.getNextVisibleString();
            sp.getNextVisibleString();
            for(let i=0;i<3;i++)
            {
                sp.getNextVisibleString();
                currentNormals.push(temNormal.data[0],temNormal.data[1],temNormal.data[2]);
                currentIds.push(0);
                currentPositions.push(sp.getNextFloat(),sp.getNextFloat(),sp.getNextFloat());
                currentColors.push(defaultColor.data[0],defaultColor.data[1],defaultColor.data[2],defaultColor.data[3]);

                result.data.shapes[0].data.boundBox.addPoint_array3_xyz(currentPositions[currentPositions.length-3],currentPositions[currentPositions.length-2],currentPositions[currentPositions.length-1]);
            }
            sp.getNextVisibleString();
            sp.getNextVisibleString();
        }
        result.data.shapes[0].data.positions=new Float32Array(currentPositions);
        result.data.shapes[0].data.normals=new Float32Array(currentNormals);
        result.data.shapes[0].data.colors=new Float32Array(currentColors);
        result.data.shapes[0].data.displacements=new Float32Array([0,0,0,0]);
        result.data.shapes[0].data.ids=new Uint16Array(currentIds);
        // clear memory
        sp=null;
        defaultColor=null;
        currentNormals=null;
        currentColors=null;
        currentPositions=null;
        currentIds=null;
        return result;
    }
/*
*
* arg{
*   meshSource,
*   loadState,
* }
*
*
* */
    function parseBinaryToSTL(arg)
    {
        let offset=80;
        let result=new Mesh();
        let currentNormals=[];
        let currentPositions=[];
        let currentColors=[];
        let currentIds=[];
        result.data.shapes.push(new Shape());
        let defaultColor=new  Vector([1,1,1,1]);
        result.data.format="stl";
        let binaryReader=new DataView(arg.meshSource);
        let num=binaryReader.getUint32(offset,true);
        console.debug("binary stl number",num);
        offset+=4;
        let temN=new Vector(3);  // avoid initalize too much , because too costing
        let temP=new Vector(3);
        for(let temIndex=0;temIndex<num;temIndex++)
        {
            // read the normal
            temN.data[0]=binaryReader.getFloat32(offset+0,true);
            temN.data[1]=binaryReader.getFloat32(offset+4,true);
            temN.data[2]=binaryReader.getFloat32(offset+8,true);

            // save the normal
            offset+=12;
            for(let i=0;i<3;i++)
            {
                for(let j=0;j<3;j++)
                {
                    temP.data[j]=binaryReader.getFloat32(offset+j*4,true);
                }
                currentPositions.push(temP.data[0],temP.data[1],temP.data[2]);
                currentColors.push(defaultColor.data[0],defaultColor.data[1],defaultColor.data[2],defaultColor.data[3]);
                currentNormals.push(temN.data[0],temN.data[1],temN.data[2]);
                currentIds.push(0);
                result.data.shapes[0].data.boundBox.addPoint3_vec3(temP);
                offset+=12;
            }
            offset+=2;
        }
		result.data.shapes[0].data.positions=new Float32Array(currentPositions);
        result.data.shapes[0].data.normals=new Float32Array(currentNormals);
        result.data.shapes[0].data.colors=new Float32Array(currentColors);
        result.data.shapes[0].data.ids=new Uint16Array(currentIds);
        result.data.shapes[0].data.displacements=new Float32Array([0,0,0,0]);

        arg.meshSource=null;
        temN=null;
        temP=null;
        currentColors=null
        currentNormals=null
        currentPositions=null;
        defaultColor=null;
        binaryReader=null;

        return result;
    }

    class Shape
    {
        constructor() {
            this.parent=null;
            this.children=new Array();
            this.data=
                {
                    boundBox:new AABB3(),
                    // each component of float32Array
                    vertices:null,
                    normals:null,
                    colors:null,
                    uvs:null,
                    ids:null,
                    displacements:null,     // 爆炸图用的位移量，一般用包围盒表示。
                    //
                    hasTexture:false,
                    webgl: {
                            drawNumber:0,
                            positionBuffer:null,
                            normalBuffer:null,
                            colorBuffer:null,
                            uvBuffer:null,
                            idBuffer:null,
                            displacementBuffer:null,
                            useTexture:false,
                            vao:null,
                        },
                    matrice: {
                        model_translate: Matrix.getIdentity(4,4),
                        model_rotate: Matrix.getIdentity(4, 4),
                        world_translate:Matrix.getIdentity(4, 4),
                        world_rotate: Matrix.getIdentity(4, 4),
                    },
                }


        }

    }


    class Mesh {
        constructor() {
            this.data =
                {
                    shapes: new Array(),
                    boundBox: new AABB3(),
                    matrice: {
                        model_translate: Matrix.getIdentity(4,4),
                        model_rotate: Matrix.getIdentity(4, 4),
                        world_translate:Matrix.getIdentity(4, 4),
                        world_rotate: Matrix.getIdentity(4, 4),

                    },
                    format: "",
                }

        }


        /*
        *   arg{
        *   meshSource,
        *   format,
        *   isBinary,
        *   loadState
        *   }
        *
        * */
        loadFromSource(arg)//meshSource,format,isBinary=false,loadState)
        {
            let dFormat=arg.format?.toLowerCase()??"";
            let result;
            switch (dFormat)
            {
                case "obj": {
                    this.clear();
                    result=parseStringToOBJ(arg);
                    this.data=result.data;
                    delete result.data;
                    result=null;
                }break;
                case "stl": {
                    this.clear();
                    if(arg.isBinary)
                    {
                        result=parseBinaryToSTL(arg);
                    }else
                    {
                        result=parseStringToSTL(arg);
                    }
                    this.data=result.data;
                    delete result.data;
                    result=null;
                }break;
                case "step": {

                }break;
                case "tkk": {

                }break;
                default:console.log("unsupported format: "+arg.format);

            }

        }

        clear()
        {
            let stack=[];
            let temShape;
            for(let i=0;i<this.data.shapes.length;i++)
            {
                stack.push(this.data.shapes[i]);
            }
            let gl=getGlobalWebgl().gl;
            while(stack.length)
            {
                temShape=stack.pop();
                temShape.data.webgl.drawNumber=0;


                temShape.data.ids=null;
                temShape.data.colorBuffer=null;
                temShape.data.positionBuffer=null;
                temShape.data.normalBuffer=null;
                temShape.data.displacements=null;
                temShape.data.uvs=null;

                if(temShape.data.webgl.positionBuffer!=null)
                {
                    gl.deleteBuffer(temShape.data.webgl.positionBuffer);
                    temShape.data.webgl.positionBuffer=null;
                }
                temShape.data.positionBuffer=null;

                if(temShape.data.webgl.normalBuffer!=null)
                {
                    gl.deleteBuffer(temShape.data.webgl.normalBuffer);
                    temShape.data.webgl.normalBuffer=null;
                }
                temShape.data.normalBuffer=null;

                if(temShape.data.webgl.colorBuffer!=null)
                {
                    gl.deleteBuffer(temShape.data.webgl.colorBuffer);
                    temShape.data.webgl.colorBuffer=null;
                }
                temShape.data.colorBuffer=null;

                if(temShape.data.webgl.uvBuffer!=null)
                {
                    gl.deleteBuffer(temShape.data.webgl.uvBuffer);
                    temShape.data.webgl.uvBuffer=null;
                }
                temShape.data.uvBuffer=null;

                if(temShape.data.webgl.idBuffer!=null)
                {
                    gl.deleteBuffer(temShape.data.webgl.uvBuffer);
                    temShape.data.webgl.idBuffer=null;
                }
                temShape.data.idBuffer=null;

                if(temShape.data.webgl.displacementBuffer!=null)
                {
                    gl.deleteBuffer(temShape.data.webgl.displacementBuffer);
                    temShape.data.webgl.displacementBuffer=null;
                }

                if(temShape.data.webgl.vao!=null)
                {
                    gl.deleteVertexArray(temShape.data.webgl.vao);
                    temShape.data.webgl.vao=null;
                }



                for(let i=0;i<temShape.children.length;i++)
                    stack.push(temShape.children[i]);

                temShape=null;
            }
            this.data.shapes=null;
            if(this.data.vao!=null)
            {
                gl.deleteVertexArray(this.data.vao);
                this.data.vao=null;
            }
            delete this.data;
            this.data =
                {
                    shapes: new Array(),
                    boundBox: new AABB3(),
                    matrix: {
                        transform: Matrix.getIdentity(4,4),
                        rotate: Matrix.getIdentity(4, 4),
                    },
                    format: "",
                }
        }

/*
        *   arg{
        *   meshSource,
        *   format,
        *   isBinary,
        *   loadState
        *   }
* */

        static createFromSource(arg)
        {
            let dFormat=arg.format.toLowerCase();
            let result;
            switch (dFormat)
            {
                case "obj": {
                    result= parseStringToOBJ(arg);
                }break;
                case "stl": {
                    if(arg.isBinary)
                    {
                        result=parseBinaryToSTL(arg);
                    }else
                    {
                        result=parseStringToSTL(arg);
                    }
                }break;
                case "step": {

                }break;
                case "tkk": {

                }break;
                default:console.log("unsupported format: "+arg.format);
            }
            return result;
        }


    }

