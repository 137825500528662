export {Vector,Matrix}

//============================================
//------------------ Vector ------------------
//============================================
class Vector
{
	constructor(lengthOrArray)
	{
		this.getObjectName=function (){
			return "LBVector";
		}
		
		if(lengthOrArray==null)
		{
			this.data=new Float32Array(3);
		}else if (typeof lengthOrArray=="number")
		{
			this.data=new Float32Array(lengthOrArray);
		}else if(Array.isArray(lengthOrArray))
		{
			this.data=new Float32Array(lengthOrArray);
		}else if(lengthOrArray.__proto__===Float32Array.prototype)
		{
			this.data=new Float32Array(lengthOrArray);
		}

	}

	/*
	* description:      get the length(norm2) of vector
	* specification:    float()
	*/
	length_2()
	{
		let result=0;
		for(let i=0;i<this.data.length;i++)
		{
			result+=this.data[i]*this.data[i];
		}
		return Math.sqrt(result);
	}

	/*
	* description:      normalize the vector
	* specification:    ()
	*/
	normalize()
	{
		let length=this.length_2();
		for(let i=0;i<this.data.length;i++)
		{
			this.data[i]/=length;
		}

	}

	dot(right)
	{
		let result=0;
		for(let i=0;i<this.data.length;i++)
			result+=this.data[i]*right.data[i];
		return result;
	}

	add_vec(vec)
	{
	   for(let i=0;i<this.data.length;i++)
	   {
		   this.data[i]+=vec.data[i]||0;
	   }

	}
	add_real(real)
	{
		let r=real||0;
		for(let i=0;i<this.data.length;i++)
		{
			this.data[i]+=r;
		}
	}

	sub_vec(vec)
	{
		for(let i=0;i<this.data.length;i++)
		{
			this.data[i]-=vec.data[i]||0;
		}
	}

	sub_real(real)
	{
		let r=real||0;
		for(let i=0;i<this.data.length;i++)
		{
			this.data[i]-=r;
		}
	}

	multiply_vec(vec)
	{
		for(let i=0;i<this.data.length;i++)
		{
			this.data[i]*=vec.data[i]||1;
		}
	}

	multiply_real(number)
	{
		let n=number||1;
		for(let i=0;i<this.data.length;i++)
		{
			this.data[i]*=n;
		}
	}


	// ====  static method  ====

	/*
	* description:      create a new vector
	* specification:    Vector(uint)
	*/
	static createNew(length)
	{
		return new Vector(length||3);
	}

	/*
	* description:      get the resized vector
	* specification:    Vector(uint)
	*/
	static toVector(length)
	{
		let l=length;
		if(length==null||length<=0)
			l=3;
		let newArray=new Float32Array(l);
		let minLength=this.data.length>l? l:this.data.length;
		for(let i=0;i<minLength;i++)
		{
			newArray[i]=this.data[i];
		}
		return newArray;
	}


	static dot(left,right)
	{
		let result=0;
		for(let i=0;i<left.data.length;i++)
			result+=left.data[i]*right.data[i];
		return result;
	}

	static cross(left,right)
	{
	let result=new Vector(3);
	result.data[0]=left.data[1]*right.data[2]-left.data[2]*right.data[1];
	result.data[1]=left.data[2]*right.data[0]-left.data[0]*right.data[2];
	result.data[2]=left.data[0]*right.data[1]-left.data[1]*right.data[0];
	return result;
	}


}

//=============================================
//------------------ Matrix -------------------
//=============================================
class Matrix {
	constructor(row, column) {
		this.getObjectName=function ()
		{
			return "LBMatrix";
		}
		this.row = row || 4;
		this.column = column || 4;
		this.data = new Float32Array(this.row * this.column);

	}

	/*
	* description:      set matrix to identity state
	* specification:    ()
	*/
	setIdentity() {
		for (let c = 0; c < this.column; c++)
			for (let r = 0; r < this.row; r++) {
				if (c == r)
					this.data[c * 4 + r] = 1;
				else
					this.data[c * 4 + r] = 0;
			}
	}


	leftMultiplyToVector(vec)
	{
		let length=vec.data.length;
		let result=new Vector(length);

		for(let r=0;r<this.row;r++)
		{
			result.data[r]=0.0;
			for(let c=0;c<this.column;c++)
			{
				result.data[r] += this.data[r+this.row*c] * vec.data[c];
			}
		}
		return result;
	}
	/*
	* description:      this matrix result = thisMatrix * right
	* specification:    (Matrix)
	*/
	multiply_right(right) {
		this.data = Matrix.multiply(this, right).data;
	}

	/*
	* description:      this matrix result = left * thisMatrix
	* specification:    (Matrix)
	*/
	multiply_left(left) {
		this.data = Matrix.multiply(left, this).data;
	}

	//======================== static method

	/*
	* description:      return left X right , result Matrix's row,column=left row,right column)
	* specification:    Matrix(Matrix,Matrix)
	*/
	static multiply(left, right) {
		let result = new Matrix(left.row, right.column);

		for (let c = 0; c < result.column; c++)
			for (let r = 0; r < result.row; r++) {
				result.data[c * 4 + r] = 0;
				for (let i = 0; i < 4; i++)
					result.data[c * 4 + r] += left.data[r + i * 4] * right.data[i + c * 4];
			}
		return result;
	}

	/*
	* description:      return a new Matrix with row*column array data;
	* specification:    (uint,uint)
	*/
	static createMatrix(row, column) {
		return new Matrix(row, column);
	}

	static getIdentity(row, column) {
		let result = new Matrix(row, column);
		result.setIdentity();
		return result;
	}

	static getScale_4X4_xyzw(x, y, z, w) {
		let result = new Matrix(4, 4);
		result.setIdentity();
		result.data[0] = x || 1;
		result.data[5] = y || 1;
		result.data[10] = z || 1;
		result.data[15] = w || 1;
		return result;
	}

	static getScale_4X4_array(xyzw) {
		let result = new Matrix(4, 4);
		result.setIdentity();
		result.data[0] = xyzw[0] || 1;
		result.data[5] = xyzw[1] || 1;
		result.data[10] = xyzw[2] || 1;
		result.data[15] = xyzw[3] || 1;
		return result;
	}

	static getTranslate_4X4_vec3(vec3) {
		let result = new Matrix(4, 4);
		result.setIdentity();
		result.data[12] = vec3.data[0] || 0;
		result.data[13] = vec3.data[1] || 0;
		result.data[14] = vec3.data[2] || 0;
		return result;
	}

	static getTranslate_4X4_xyz(x, y, z) {
		let result = new Matrix(4, 4);
		result.setIdentity();
		result.data[12] = x || 0;
		result.data[13] = y || 0;
		result.data[14] = z || 0;
		return result;
	}

	static getTranslate_4X4_array(xyz) {
		let result = new Matrix(4, 4);
		result.setIdentity();
		result.data[12] = xyz[0] || 0;
		result.data[13] = xyz[1] || 0;
		result.data[14] = xyz[2] || 0;
		return result;
	}

	static getRotate_angle_4X4_xyz(angle, X, Y, Z) {
		let l = new Vector([X, Y, Z]);
		l.normalize();
		let x = l.data[0];
		let y = l.data[1];
		let z = l.data[2];
		let result = new Matrix(4, 4);
		result.setIdentity();

		let s = Math.sin((angle * Math.PI) / 180);
		let c = Math.cos((angle * Math.PI) / 180);
		result.data[0] = c + (1 - c) * x * x;
		result.data[1] = (1 - c) * x * y + s * z;
		result.data[2] = (1 - c) * x * z - s * y;

		result.data[4] = (1 - c) * x * y - s * z;
		result.data[5] = c + (1 - c) * y * y;
		result.data[6] = (1 - c) * y * z + s * x;

		result.data[8] = (1 - c) * x * z + s * y;
		result.data[9] = (1 - c) * y * z - s * x;
		result.data[10] = c + (1 - c) * z * z;
		return result;
	}

	static getRotate_angle_4X4_array(angle,xyz)
	{
		let l = new Vector(xyz);
		l.normalize();
		let x = l.data[0];
		let y = l.data[1];
		let z = l.data[2];
		let result = new Matrix(4, 4);
		result.setIdentity();

		let s = Math.sin((angle * Math.PI) / 180);
		let c = Math.cos((angle * Math.PI) / 180);
		result.data[0] = c + (1 - c) * x * x;
		result.data[1] = (1 - c) * x * y + s * z;
		result.data[2] = (1 - c) * x * z - s * y;

		result.data[4] = (1 - c) * x * y - s * z;
		result.data[5] = c + (1 - c) * y * y;
		result.data[6] = (1 - c) * y * z + s * x;

		result.data[8] = (1 - c) * x * z + s * y;
		result.data[9] = (1 - c) * y * z - s * x;
		result.data[10] = c + (1 - c) * z * z;
		return result;
	}
	static getRotate_radian_4X4_xyz(radian, X, Y, Z) {
		let l = new Vector([X, Y, Z]);
		l.normalize();
		let x = l.data[0];
		let y = l.data[1];
		let z = l.data[2];
		let result = new Matrix(4, 4);
		result.setIdentity();

		let s = Math.sin(radian);
		let c = Math.cos(radian);
		result.data[0] = c + (1 - c) * x * x;
		result.data[1] = (1 - c) * x * y + s * z;
		result.data[2] = (1 - c) * x * z - s * y;

		result.data[4] = (1 - c) * x * y - s * z;
		result.data[5] = c + (1 - c) * y * y;
		result.data[6] = (1 - c) * y * z + s * x;

		result.data[8] = (1 - c) * x * z + s * y;
		result.data[9] = (1 - c) * y * z - s * x;
		result.data[10] = c + (1 - c) * z * z;
		return result;
	}

	static getRotate_angle_Z(angle) {
		let result = new Matrix(4, 4);
		result.setIdentity();

		let s = Math.sin((angle * Math.PI) / 180);
		let c = Math.cos((angle * Math.PI) / 180);
		result.data[0] = c;
		result.data[1] = s;
		result.data[4] = -s;
		result.data[5] = c;
		return result;
	}

	static getRotate_angle_X(angle) {
		let result = new Matrix(4, 4);
		result.setIdentity();

		let s = Math.sin((angle * Math.PI) / 180);
		let c = Math.cos((angle * Math.PI) / 180);
		result.data[5] = c;
		result.data[6] = s;
		result.data[9] = -s;
		result.data[10] = c;
		return result;
	}

	static getRotate_radian_X(radian) {
		let result = new Matrix(4, 4);
		result.setIdentity();

		let s = Math.sin(radian);
		let c = Math.cos(radian);
		result.data[5] = c;
		result.data[6] = s;
		result.data[9] = -s;
		result.data[10] = c;
		return result;
	}

	static getRotate_angle_Y(angle) {
		let result = new Matrix(4, 4);
		result.setIdentity();

		let s = Math.sin((angle * Math.PI) / 180);
		let c = Math.cos((angle * Math.PI) / 180);
		result.data[0] = c;
		result.data[2] = -s;
		result.data[8] = s;
		result.data[10] = c;
		return result;
	}

	static getLookAt_4X4_array(eyePosition, lookDirection, upDirection) {
		let result = new Matrix(4, 4);
		let Y = new Vector(3);
		let Z = new Vector(3);
		for (let i = 0; i < 3; i++) {
			Z.data[i] = -lookDirection[i];
			Y.data[i] = upDirection[i];
		}
		Z.normalize();
		let X = Vector.cross(Y, Z);
		X.normalize();
		Y = Vector.cross(Z, X);
		Y.normalize();
		let matrix_move = Matrix.getTranslate_4X4_xyz(-eyePosition[0],-eyePosition[1],-eyePosition[2]);
		let matrix_transform = new Matrix(4, 4);
		matrix_transform.setIdentity();
		for (let i = 0; i < 3; i++) {
			matrix_transform.data[4 * i] = X.data[i];
			matrix_transform.data[1 + 4 * i] = Y.data[i];
			matrix_transform.data[2 + 4 * i] = Z.data[i];
		}

		return Matrix.multiply(matrix_transform, matrix_move);
	}

	static getPerspective(fov_H, WDH, n, f) {
		let result = new Matrix(4, 4);
		result.setIdentity();
		fov_H = (fov_H * 3.1415926) / (2 * 180);
		let t = 1 / Math.tan(fov_H); // the length of y
		result.data[0] = t / WDH;
		result.data[5] = t;
		result.data[10] = (f + n) / (n - f);
		result.data[11] = -1;
		result.data[14] = 2 * f * n / (n - f);
		result.data[15] = 0;
		return result;
	}

	static getOrthogonal(width, WDH, n, f)
	{
		let result=new Matrix(4,4);
		result.setIdentity();
		let temWidth=width/2;
		result.data[0]=1/temWidth;
		result.data[5]=WDH/temWidth;
		result.data[10]=2.0/(n-f);
		result.data[14]=(f+n)/(n-f);
		return result;
	}
}




function matrix_multiply_vector(matrix,vec4)
{
	let result=new Vector(4);
	for(let i=0;i<4;i++)
	{
		result.data[i]=0.0;
		for(let j=0;j<4;j++)
		{
			result.data[i] += matrix.data[i*4+j] * vec4.data[j];
		}
	}
	return result;
}



