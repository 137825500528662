<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-15 14:22:14
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\PageMain\Shouji\Bottom.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="box-bd clearfix">
    <div class="row">
      <div class="rowzong">
    <div class="row-img">
      <img src="../../../assets/imgs/TKK/tutwoo.png"  class="row-img-one">
      <img src="../../../assets/imgs/TKK/tutwos.png" class="row-img-two">
    </div>
    <div class="row-text">
      <div class="row-texts">
        <p style="color:rgba(255, 195, 0, 1);">点赞评论，一键收藏</p>
        <p>社交属性赋能数字化传播</p>
      </div>
      <div class="row-textss">
        <p>展示除图片，视频之外的产品结构，尺寸，装配等</p>
        <p>技术特征，支持点赞，评论，下载，分享功能</p>
        <p>赋予产品社交属性，数字化传播</p>
      </div>
    </div>
</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>

.row{
  width: 100%;
  height: 70vh;
  background: rgba(118, 130, 166, 1);
  .rowzong{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
}
  .row-img{
     width: 100%;
  }
  .row-img-one{
     position: relative;
    left: 5vw;
    top: 5vh;
    width:15vw; 
    height: 60vh;
  }
    .row-img-two{
     position: relative;
    left: 5vw;
    top: -45vh;
    z-index: 2;
    opacity: 0;
     transition: 1s;
      width:25vw; 
  }
   .row-img-two:hover{
     opacity: 1;
      transform:scale(1.2, 1.2);
   }
  .row-text{
    width: 100%;
    
    // margin: auto;
    margin-right: 10vh;
    margin-top: 10vw;
    text-align: right;
    color:#ffffff;
  
    .row-texts{
      font-size: 35px;
    }
    .row-textss{
      color: #ffffff;
      margin-top: 50px;
      font-size: 20px;
    }
  }
}


</style>