<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-08-18 17:33:23
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\header\SiteTopbar.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="add">
    <div class="topbar">
      <div class="header-left">
        <img src="../../assets/imgs/TKK/logo (2).png" width="120px" style="margin-top:6px;"/>
      </div>
      <div class="header-right">
        <div class="head-shou" @click="$router.replace({path:'/Index'})">首页</div>
        <div class="head-mian">图哐哐APP</div>
        <div>
          <!-- 免费下载
            <div class="head-botton-img">
              <img src="../../assets/imgs/TKK/code.png">
          </div>-->
          <el-popover placement="top" width="140" >
            <p style="text-align: center;">扫码下载</p>
            <div style="text-align: center; margin: 0">
              <img src="../../assets/imgs/TKK/43.jpg" width="80%" />
            </div>
            <el-button type="primary" slot="reference" class="row-button">免费下载</el-button>
          </el-popover>

          <!-- <el-button type="text" @click="open">点击打开 Message Box</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteTopbar",
  data() {
    return {
      visible:'',
      img: "../../assets/imgs/TKK/code.png"
    };
  },
  methods: {
    // showCart() {
    //   this.CartHeight = 100;
    //   var cart = document.querySelector('.cart-mini');
    //   cart.style.color = '#fff';
    //   cart.style.background = '#479aff'
    // },
    // closeCart() {
    //   this.CartHeight = 0;
    //   var cart = document.querySelector('.cart-mini');
    //     cart.style.color = '';
    //     cart.style.background = ''

    // },

    open() {
      const url = require("../../assets/imgs/TKK/code.png");
      this.$alert('<img :src="this.img" />', "扫码下载", {
        dangerouslyUseHTMLString: true
      });
    }
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0px;
  padding: 0px;
}
.add {
  background: #ffffff;
  position: fixed;
  z-index: 100;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}
.topbar {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 6vh;
  justify-content: space-between;
  align-items: center;
}
.header-right {
  width: 30%;

  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
.head-shou {
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.head-mian {
  width: 55%;
  text-align: right;
  height: 40px;
  line-height: 40px;
}
.head-botton {
  width: 30%;
  cursor: pointer;
  height: 40px;
  background: rgb(85, 127, 255);
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  border-radius: 4px;
}
.row-button{
  display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 4px;
        color: #FFF;
    background-color: #409EFF;
    border-color: #409EFF;
}
</style>