<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-25 16:33:53
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\PageMain\ShiPing\Bottom.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="box-bd clearfix">
    <div class="row">
    <div class="title">
        <p>科技赋能 合作共赢</p>
    </div>
    <div class="content">
      <p>以用户为中心，服务智能制造</p>
    </div>
    <div class="seation">
      <div class="seation-content">
        <div class="seation-img">
          <img src="../../../assets/imgs/TKK/tuthree.png" width="260px" height="210px">
        </div>
        <div class="seation-title">
          <p>服务500+</p>
        </div>
        <div class="seation-ul"> 
            <ul>
              <li>国产软件，安全保障</li>
              <li>智能制造，数字化</li>
              <li>科技赋能，提质增效</li>
            </ul>
        </div>
      </div>

      <div class="seation-content">
        <div class="seation-img">
          <img src="../../../assets/imgs/TKK/tufour.png" width="260px" height="210px">
        </div>
        <div class="seation-title">
          <p>服务500,000+</p>
        </div>
        <div class="seation-ul"> 
            <ul>
              <li>CAD专业分享，价值创造</li>
              <li>上传3D，粉丝流量圈</li>
              <li>付费下载，灵活职业</li>
            </ul>
        </div>
      </div>

      <div class="seation-content">
        <div class="seation-img">
          <img src="../../../assets/imgs/TKK/tufive.png" width="260px" height="210px">
        </div>
        <div class="seation-title">
          <p>服务100+</p>
        </div>
        <div class="seation-ul"> 
            <ul>
              <li>3D教育，趣味生动</li>
              <li>3D教育，真实高效</li>
              <li>3D教育，全面广阔</li>
            </ul>
        </div>
      </div>

      <div class="seation-content">
        <div class="seation-img">
          <img src="../../../assets/imgs/TKK/tusix.png" width="260px" height="210px">
        </div>
        <div class="seation-title">
          <p>服务20,000+</p>
        </div>
        <div class="seation-ul"> 
            <ul>
              <li>3D电商，新场景</li>
              <li>3D零售，新生态</li>
              <li>3D制造，新技术</li>
            </ul>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.box-bd .clearfix {
  width: 1240px;
  height: 285px;
  margin: 0;
  padding: 0;
  list-style-type: none;
 
}
li{
   list-style-type: none;
   margin-top: 10px;
   color: rgba(69, 77, 89, 0.6);
   font-size: 14px;
}
.row{
  border-radius: 10px;
  width: 100%;
  height: 640px;
  margin-top: 80px;
  .title{
    font-size: 40px;
    text-align: center;
  }
  .content{
    text-align: center;
    color: rgba(69, 77, 89, 0.6);
    letter-spacing: 16px;
font-size: 24px;
line-height: 34px;
font-weight: 400;
margin-top: 20px;
  }
  .seation{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    .seation-title{
      margin-top: 20px;
      color: rgba(33, 33, 33, 1);
font-size: 20px;
font-weight: 400;
    }
  }
}
img{
  transition: 0.5s;
  cursor: pointer;
}
img:hover{
  transform:scale(1.1,1.1);
}

</style>