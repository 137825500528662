
export {Camera3D}
import {Matrix,Vector} from "./Math.js"

class Camera3D
{
	constructor() {
		this.state=
			{
				position:[1000,0,0],
				direction_up:[0,1,0],
				position_look:[0,0,0],
				WDH:1,
				near:0.01,
				far:1000000,
				focusMode:true,     //  true: track ball
									//  false: free camera
				speed_move:0.5, // basic length
				speed_waggle:0.5, // angle
				perspective:
					{
						fov_H:90,
					},
				orthogonal:
					{
						width:100,
					},

			};

		this.getObjectName=function (){
			return "LBCamera3D";  
		}
	}
	getLookDirection()
	{
		let result=[];
		for(let i=0;i<3;i++)
			result.push(this.state.position_look[i]-this.state.position[i]);
		return result;
	}
	move(direction,length)
	{
		let ml=length??this.state.speed_move;
		let temVec=new  Vector(3);
		let temVec1=new  Vector(3);
		if(!this.state.focusMode)
		{

			switch (direction)
			{
				case "up":
				{
					this.state.position[1]+=ml;
					this.state.position_look[1]+=ml;
				}break;
				case "down":{
					this.state.position[1]-=ml;
					this.state.position_look[1]-=ml;

				}break;
				case "left":{
					for (let i=0;i<3;i++)
					{
						temVec.data[i]=this.state.position_look[i]-this.state.position[i];
					}
					// temVec look direction
					temVec1.data=this.state.direction_up;// up direction
					temVec= Vector.cross(temVec1,temVec);
					temVec.normalize(); // left direction
					temVec.multiply_real(ml);
					for(let i=0;i<3;i++)
					{
						this.state.position[i]+=temVec.data[i];
					}
				}break;
				case "right":{
					for (let i=0;i<3;i++)
					{
						temVec.data[i]=this.state.position_look[i]-this.state.position[i];
					}
					// temVec look direction
					temVec1.data=this.state.direction_up;// up direction
					temVec= Vector.cross(temVec1,temVec);
					temVec.normalize(); // left direction
					temVec.multiply_real(ml);
					for(let i=0;i<3;i++)
					{
						this.state.position[i]+=temVec.data[i];
					}
				}break;
				case "front":{
					// for(let i=0;i<3;i++)
					// 	temVec.data[i]=this.state.position_look[i]-this.state.position[i];
					// temVec.normalize();
					// let p=new Vector(3);
					// p.data=this.state.position;
					// temVec.multiply_real(ml);
					// p.add_vec(temVec);
					//this.state.position=p.data;
					this.state.orthogonal.width-=ml;
				} break;
				case "back" :{
					for(let i=0;i<3;i++)
						temVec.data[i]=this.state.position_look[i]-this.state.position[i];
					temVec.normalize();
					let p=new  Vector(3);
					p.data=this.state.position;
					temVec.multiply_real(ml);
					p.add_vec(temVec);
					this.state.position=p.data;
					this.state.orthogonal.width+=ml;
				}break;
			}
		}
		else
		{
			switch (direction)
			{
				case "front":{
					if(this.state.orthogonal.width>ml)
						this.state.orthogonal.width-=ml;
				}break;
				case "back":{

					this.state.orthogonal.width+=ml;
				}break;
			}
		}

	}

	waggle(direction,angle)
	{

		if(this.state.focusMode)
		{
			switch (direction)
			{
				case "up": {
					angle=1
				}break;
				case "down":{
					angle=1
				}break;
				case "left":{
					angle=1
				}break;
				case "right":{
					angle=1
				}break;
			}

		}else
		{
			switch (direction)
			{
				case "up":{
					angle=1
				}break;
				case "down":{
					angle=1
				}break;
				case "left" :{
					angle=1
				}break;
				case "right" :{
					angle=1
				}break;

			}
		}

	}


	getMatrix4X4_perspective()
	{
		return  Matrix.getPerspective(this.state.perspective.fov_H,this.state.WDH,this.state.near,this.state.far);
	}

	getMatrix4X4_orthogonal()
	{
		return  Matrix.getOrthogonal(this.state.orthogonal.width,this.state.WDH,this.state.near,this.state.far);
	}

	getMatrix4X4_view()
	{
		let lookDirection=[];
		for(let i=0;i<3;i++)
			lookDirection.push(this.state.position_look[i]-this.state.position[i]);
		return  Matrix.getLookAt_4X4_array(this.state.position,lookDirection,this.state.direction_up);
	}




}

