import axios from 'axios'

export function request(config) {
  // axios({
  //   url: config
  // }).then(res=>{
  //   success(res)
  // }).catch(err=>{
  //   fail(err)
  // })
  // ------------------------------------------------------------------------
  // axios.defaults.baseURL='https://jsonplaceholder.typicode.com/';
  // axios(config).then(
  //   res=>{
  //     config.success(res.data,'1111111111')
  //   }
  // ).catch(err=>{
  //   config.fail(err)
  // })
  // ------------------------------------------------------------------------
  let newRE = axios.create({
    baseURL: 'https://jsonplaceholder.typicode.com/'
  });
  return new Promise((resolve,reject) => {
    newRE(config).then(res=>{
      resolve(res.data)
    }).catch(err=>{
      reject(err)
    })
  })
}