/*
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-08 15:53:59
 * @FilePath: \vue-official-website-of-xiaomi-master\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './viewport.js'

//全局css
import './assets/css/gloab.css'
import './assets/font/iconfont.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

import {tkk3DEngine} from '@/util/tkk3DEngine/tkk3DEngine.js'//./util/tkk3DEngine/tkk3DEngine
console.log(tkk3DEngine)

import { request } from './request/request'



// request('https://jsonplaceholder.typicode.com/posts1',res=>{
//   console.log(res);
// },err=>{
//   console.log(err);
// })
// -------------------------------------------
// request({
//   url: 'posts',
//   success: res=>{
//     console.log(res);
//   },
//   fail: err=>{
//     console.log(err);
//   }
// })
// -------------------------------------------
request('posts')
.then(res=>{
  console.log(res);
}).catch(err=>{
  console.log(err);
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
