/*
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-08-02 15:16:24
 * @FilePath: \vue-official-website-of-xiaomi-master\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE 
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/Index' },
  { path: '/Index', component: Index  },
  { path: '/New_Page/test', component: () => import('@/components/New_Page/test') },
  { path: '/Brief/jianjie', component: () => import('@/components/Brief/jianjie') },
  { path: '/recruit/recruit', component: () => import('@/components/recruit/recruit') },
  { path: '/agreement/yinsi', component: () => import('@/components/agreement/yinsi') },
  { path: '/agreement/xieyi', component: () => import('@/components/agreement/xieyi') },
  { path: '/Design/tkk3D', component: () => import('@/components/Design/tkk3D') },
  { path: '/Design/zhaopin', component: () => import('@/components/Design/zhaopin') },
  { path: '/ceshi/ces', component: () => import('@/components/ceshi/ces') },
  { path: '/Lunbo/ceshi', component: () => import('@/components/Lunbo/ceshi') },
]

const router = new VueRouter({
  // mode: 'history',
  // base:'/dist/',
  routes
})

export default router
