export {StringParser}
import {Vector,Matrix} from "./Math.js"

    class StringParser
    {
        constructor(text)
        {
            this.state=
                {
                    content:text||" ",
                    start:0,
                    end:1,
                }
        }
        setContent(text)
        {
			this.state.content=null;
            this.state=
                {
                    content:text||" ",
                    start:0,
                    end:-1,
                }
        }
        getNextVisibleString()
        {
            this.state.start=this.state.end+1;
            while(this.state.start<this.state.content.length&&(this.state.content[this.state.start]==' '||this.state.content[this.state.start]=='\t'||this.state.content[this.state.start]=='\n'||this.state.content[this.state.start]=='\r'))
            {
                this.state.start++;
            }
            if(this.state.start>=this.state.content.length)
                return "";
            this.state.end=this.state.start;
            while (this.state.end<this.state.content.length&&(this.state.content[this.state.end]!=' '&&this.state.content[this.state.end]!='\t'&&this.state.content[this.state.end]!='\n'&&this.state.content[this.state.end]!='\r'))
            {
                this.state.end++;
            }
            return this.state.content.substring(this.state.start,this.state.end);
        }


        getNextFloat()
        {
            return parseFloat(this.getNextVisibleString());
        }
        jumpToNextLine()
        {
            this.state.start=this.state.end+1;
            while(this.state.start<this.state.content.length && this.state.content[this.state.start]!='\n')
            {
                this.state.start++;
            }

            this.state.end=this.state.start;
        }
        getNextChar()
        {
            this.state.start=this.state.end+1;

            while(this.state.start<this.state.content.length&&this.state.content[this.state.start]==' '||this.state.content[this.state.start]=='\t'||this.state.content[this.state.start]=='\r'||this.state.content[this.state.start]=="\n")
            {
                this.state.start++;
            }
            if(this.state.start>=this.state.content.length)
                return "";
            this.end=this.state.start;
            return this.state.content[this.state.start];
        }
        getNextUInt()
        {
            this.state.start=this.state.end+1;
            while(this.state.start<this.state.content.length&&(this.state.content[this.state.start]<'0'||this.state.content[this.state.end]>'9') )
            {
                this.state.start++;
            }
            if(this.state.start>=this.state.content.length)
                return "";
            this.state.end=this.state.start;
            while (this.state.end<this.state.content.length&&this.state.content[this.state.end]>='0'&&this.state.content[this.state.end]<='9')
            {
                this.state.end++;
            }
            return parseInt(this.state.content.substring(this.state.start,this.state.end));

        }
        hasNextUInt()
        {
            for(let i=this.state.end+1;i<this.state.content.length;i++)
            {
                if(this.state.content[i]>='0'&&this.state.content[i]<='9')
                    return true;
            }
            return false;
        }


    }

