export {tkk3DEngine}

import {Vector,Matrix,Mesh,Shape,Util,AABB3,Camera3D,Controller,WebglRenderer,getGlobalWebgl,Pipeline,Base64,Queue,Node_Shared,Stack,BindData} from "../tkk3DEngine/importBundle.js"
	
"use strict"
class tkk3DEngine {
	constructor() {
		this.state = {
			// 引擎是否已经初始化,防止初始化u动词
			isInitial: false,
			// 用来负责管理渲染目标的类
			webglRenderer: new WebglRenderer(),
			// 绘制流程，每次 循环 系统的绘制 就是 根据 这里面的名字 找到对应 的 drapass 进行 绘制
			renderPass: new Array(0),
			functional: {
				autoRotate: {
					enable: true,	// is enable
					speed: 10.0, // angle per second
					rotateAxes:[0,1,0],
				},
			},
			// 放着各种资源的变量
			resource: {
				textures: {
					skySphere: null,
				},
				// 渲染管线
				pipelines:
					{
						defaultBackground_App: null,
						defaultModel_App: null,
						defaultBackGround_Website: null,
						defaultModel_Website: null,
					},
				// 渲染通道
				drawPass:
					{
						// app 默认背景渲染流程
						defaultBackground_App: {
							isInitialize: false,
							initializeFunction: null,
							drawFunction: null,
						},
						// app 默认模型渲染流程
						defaultModel_App: {
							isInitialize: false,
							initializeFunction: null,
							drawFunction: null,
						},
						// 网站 默认背景渲染流程
						defaultBackground_Website: {
							isInitialize: false,
							initializeFunction: null,
							drawFunction: null,
						},
						// 网站 默认模型渲染流程
						defaultModel_Website: {
							isInitialize: false,
							initializeFunction: null,
							drawFunction: null,
						},
						// 爆炸图 渲染流程

					},
				attributeFunction:
					{
						get: {},
						set: {}
					},

			},
			// 打算用来进行多线程读取模型的worker，目前还没写好
			workers: {
				loadModel: null,
			},
			// 相机
			camera: new Camera3D(),

			cubeMap: null,
			// 模型
			mesh: new Mesh(),

			skySphereTexture: null,
			// 用来管理手势操作的类
			modelControl: new Controller(),
			// 放着渲染相关的性能监测信息
			renderInfo:
				{
					interval:0,
					lastTimeStamp: new Date().getTime() / 1000.0,
					framesPerSecond: 0, // fps
					renderFinished: true, // 本帧是否绘制完毕
					needRender: true,	// 是否需要进行绘制下一帧
					totalFrame: 0,	// 一共绘制了多少帧
				},
			// 自系统启动来开始统计事件,单位应该是m/s 或者ms/s ，自己看一下相关代码
			time: {
				start: 0.0,
			},
			// 用来操作模型的矩阵
			matrixs:
				{
					world_rotate: Matrix.getIdentity(4, 4),
					//Matrix.multiply(Matrix.getRotate_angle_Z(-30), Matrix.multiply(Matrix.getRotate_angle_Y(70) ,Matrix.getRotate_angle_X(0)));
					world_translate: Matrix.getIdentity(4, 4),
					view: null,
					project: null,
				},
			// 放着引用的外部库，可有可无，主要不想一个库请求多次，所以，如果有需要的库就第一次请求然后挂载到这里，后面要用就从这里拿，比如说pako
			importModule:
				{}
		};

		this.initializeEngine();


	}

	clear() {
		this.state.mesh.clear();
		delete this.state;
	}

	/*
	* 	接口： setTargetCanvas
	* 	作用： 让 引擎 绘制 图像 到 传入参数指定 的 画布，指定后，引擎会切换渲染目标画布，然后 覆盖掉 指定的画布 的 鼠标事件 ，指派 自己的 各种鼠标事件
	* 	parameter：
	* 		canvas ： 要求传入一个画布
	*	可以用setAttribute("targetCanvas",canvas)起到同样效果
	*
	* */
	setTargetCanvas(canvas)
	{
		if (canvas?.nodeName == null || canvas.nodeName != "CANVAS") {
			console.log("TkkEngine.setRenderTarget(): fail to set render target canvas with ");
			return;
		}
		let oldWDH = this.state.camera.state.WDH;
		this.state.camera.state.WDH = (canvas.offsetWidth + 0.001) / (canvas.offsetHeight + 0.001);
		if (oldWDH != this.state.camera.state.WDH)
			this.state.matrixs.project.data = this.state.camera.getMatrix4X4_orthogonal();
		this.state.webglRenderer.setResolution(canvas.width, canvas.height);
		this.state.webglRenderer.setTargetCanvas(canvas);
		this.state.modelControl.setTargetCanvas(canvas);

		this.state.modelControl.update();
	}

	/*
	* 	接口： startRenderLoop
	* 	作用： 让引擎开始循环绘制
	*
	* 	细节： 执行这个函数前需要指定 要运行的drawPass,通过改变 this.state.renderPass来指定
	* 	例子：
	* 			let engine = new tkk3DEngine();
	* 			let canvas = document.createElment('canvas');
	* 			engine.setTargetCanvas(canvas);
    *   		this.engine.state.renderPass=["defaultBackground_Website","defaultModel_Website"];
    *   		engine.startRenderLoop();
	* */
	startRenderLoop() {
		let that = this;

		let rl = function () {

			if (that.state.renderInfo.needRender && that.state.renderInfo.renderFinished) {
				let now=new Date().getTime() / 1000.0;
				that.state.renderInfo.interval=now-that.state.renderInfo.lastTimeStamp;
				that.state.renderInfo.lastTimeStamp = now;
				that.state.renderInfo.interval=
				that.update()

				that.state.webglRenderer.renderWithFunction(that.render);
			}
			requestAnimationFrame(rl);
		}
		rl();

	}

	generateWebglBuffer(mesh) {
		console.log("generating webgl buffer")
		this.state.renderInfo.needRender = false;
		let gl = getGlobalWebgl().gl;

		let stack = new Stack();
		let queue = new Queue();
		for (let i = 0; i < mesh.data.shapes.length; i++)
			queue.push(mesh.data.shapes[i]);
		let temShape;
		while (queue.size()) {
			temShape = queue.pop();
			for (let i = 0; i < temShape.children.length; i++)
				queue.push(temShape.children[i]);
			stack.push(temShape);
		}
		temShape = null;
		queue = null;

		let normals;
		let temV1 = new Vector(3);
		let temV2 = new Vector(3);
		let temV3 = new Vector(3);


		while (stack.size() > 0) {
			temShape = stack.pop();
			// there generate webglbuffer
			temShape.data.webgl.vao = gl.createVertexArray();
			gl.bindVertexArray(temShape.data.webgl.vao);
			temShape.data.webgl.positionBuffer = gl.createBuffer();
			temShape.data.webgl.normalBuffer = gl.createBuffer();
			temShape.data.webgl.colorBuffer = gl.createBuffer();
			temShape.data.webgl.idBuffer = gl.createBuffer();
			temShape.data.webgl.displacementBuffer = gl.createBuffer();

			// if not has normal , calculate normal firstly

			if (temShape.data.normals == null || temShape.data.normals.length == 0) {
				normals = null;
				normals = [];
				for (let i = 0; i < temShape.data.positions.length; i += 9) {
					for (let j = 0; j < 3; j++) {
						temV1.data[j] = temShape.data.positions[j + i];
						temV2.data[j] = temShape.data.positions[i + j + 3];
						temV3.data[j] = temShape.data.positions[i + j + 6];
					}
					temV1.sub_vec(temV2);
					temV2.sub_vec(temV3);
					temV3 = Vector.cross(temV1, temV2);
					for (let j = 0; j < 3; j++) {
						normals.push(temV3.data[0]);
						normals.push(temV3.data[1]);
						normals.push(temV3.data[2]);
					}
				}
				temShape.data.normals = new Float32Array(normals);
			}

			normals = null;
			// then add boundbox

			gl.bindBuffer(gl.ARRAY_BUFFER, temShape.data.webgl.positionBuffer);
			gl.bufferData(gl.ARRAY_BUFFER, temShape.data.positions, gl.STATIC_DRAW);
			gl.vertexAttribPointer(0, 3, gl.FLOAT, false, 0, 0);
			gl.enableVertexAttribArray(0);

			gl.bindBuffer(gl.ARRAY_BUFFER, temShape.data.webgl.normalBuffer);
			gl.bufferData(gl.ARRAY_BUFFER, temShape.data.normals, gl.STATIC_DRAW);
			gl.vertexAttribPointer(1, 3, gl.FLOAT, true, 0, 0); // 1 normal
			gl.enableVertexAttribArray(1);

			gl.bindBuffer(gl.ARRAY_BUFFER, temShape.data.webgl.colorBuffer);
			gl.bufferData(gl.ARRAY_BUFFER, temShape.data.colors, gl.STATIC_DRAW);
			gl.vertexAttribPointer(2, 4, gl.FLOAT, false, 0, 0);
			gl.enableVertexAttribArray(2);

			gl.bindBuffer(gl.ARRAY_BUFFER, temShape.data.webgl.idBuffer);
			gl.bufferData(gl.ARRAY_BUFFER, temShape.data.ids, gl.STATIC_DRAW);
			gl.vertexAttribPointer(4, 1, gl.UNSIGNED_SHORT, false, 0, 0);
			gl.enableVertexAttribArray(4);

			gl.bindBuffer(gl.UNIFORM_BUFFER, temShape.data.webgl.displacementBuffer);
			gl.bufferData(gl.UNIFORM_BUFFER, 32000, gl.STATIC_DRAW);
			gl.bufferSubData(gl.UNIFORM_BUFFER, 0, temShape.data.displacements)

			temShape.data.webgl.drawNumber = temShape.data.positions.length / 3;

			gl.bindVertexArray(null);
			gl.bindBuffer(gl.ARRAY_BUFFER, null);
			gl.bindBuffer(gl.UNIFORM_BUFFER, null);

			let currentBoundbox;
			//temShape.state.matrixs
			if (temShape.parent == null) {
				currentBoundbox = temShape.data.boundBox.getMaxPosition();
				mesh.data.boundBox.addPoint_array3(currentBoundbox);
				currentBoundbox = temShape.data.boundBox.getMinPosition();
				mesh.data.boundBox.addPoint_array3(currentBoundbox);
			} else {
				currentBoundbox = temShape.data.boundBox.getMaxPosition();
				temShape.parent.data.boundBox.addPoint_array3(currentBoundbox);
				currentBoundbox = temShape.data.boundBox.getMinPosition();
				temShape.parent.data.boundBox.addPoint_array3(currentBoundbox);
			}
			// transmit boundbox to parent ,if don't have ,to mesh

			// end generate webglbuffer
		}

		temShape = null;

		temV1 = null, temV2 = null, temV3 = null;
		let tem1 = mesh.data.boundBox.getMaxPosition();
		let tem2 = mesh.data.boundBox.getMinPosition();
		let tem3 = [];
		let tem4 = [];
		for (let i = 0; i < 3; i++) {
			tem3.push(-(tem1[i] + tem2[i]) / 2.0);	// middle position
			tem4.push(tem1[i] - tem2[i]);
		}

		mesh.data.matrice.model_translate.data = Matrix.getTranslate_4X4_array(tem3).data;
		// for test

		console.log(tem3);
		let aaa = mesh.data.shapes[0].data.displacements;
		console.log(aaa)

		for (let i = 0; i < aaa.length / 4; i += 4) {
			for (let j = 0; j < 3; j++) {
				aaa[i + j] += tem3[j];
			}
		}

		aaa = null;
		// end test
		let maxWidth = tem4[0] > tem4[1] ? tem4[0] : tem4[1];
		maxWidth = tem4[2] > tem4[1] ? tem4[2] : tem4[1];

		this.state.camera.state.position = [maxWidth * 10, 0, 0];
		this.state.camera.state.near = 0.1;
		this.state.camera.state.far = 20 * maxWidth;
		if (this.state.camera.state.WDH > 1)
			maxWidth *= this.state.camera.state.WDH;
		this.state.camera.state.orthogonal.width = maxWidth * 1.5;
		this.state.matrixs.view.data = this.state.camera.getMatrix4X4_view().data;
		this.state.matrixs.project.data = this.state.camera.getMatrix4X4_orthogonal().data;

		// this.state.renderInfo.needRender=true;
		this.state.modelControl.setRotateMatrix(mesh.data.matrice.world_rotate);
		this.state.modelControl.setTranslateMatrix(mesh.data.matrice.world_translate);
		this.state.modelControl.update();

		console.log("finish generate webgl buffer ")
		this.state.renderInfo.needRender = true;

	}

	/*
    *
    *   arg{
    *   meshSource,
    *   format,
    *   isBinary,
    *   loadState
    *   }
    *
    * */


	/*
	* 	接口： loadMeshFromSource
	* 	作用： 读取模型
	* 	parameter：
	* 		arg ： 要求传入一个对象，该对象的格式为
	* 			arg={
	*	 				format:			文件格式，要求小写，例如"obj","stl"等等。
	*	 				filename:		文件名称，例如"123.obj","dragon.stl"。
	*	 				isBinary:		文件是否为二进制，true 或者 false ， stl 有 二进制 和 ascii 两种格式。
	*	 				meshSource:		模型源文件，如果 isBinary 为 true ，需要传入 string 。如果 isBinary 为 false ，需要传入 arrayBuffer。
	*	 				loadState:{		这时回调事件，如果模型加载到一定程度会传值回去
	* 								ratio:				此次任务在总任务占的比重，正常为1，如果要指派子任务函数，子任务相当于总工作量的30%，将ratio设为0.3，子工作完成时会将prograss+=ratio*1
	* 								prograss:			模型加载进度，0到1之间，为浮点数，0为未加载，1为加载完毕。
	* 								callBackfuntion: 	回调函数，如果加载进度更新，会调用这个函数
	* 						  	}
	* 				}
	*
	*
	* */
	loadMeshFromSource(arg) {
		if (arg.loadState != null) {
			if (arg.loadState.prograss == null)
				arg.loadState.prograss = 0.0;
			if (arg.loadState.ratio == null)
				arg.loadState.ratio = 1.0;
			if (arg.loadState.stop == null)
				arg.loadState.stop = false;
		} else {
			arg.loadState = {
				prograss: 0.0,
				loadState: 1.0,
				stop: false,
			}
		}

		if (arg.loadState.stop == true)
			return;
		this.state.mesh.clear();
		if (arg.filename != null) {
			let lowFormat = Util.getSuffixFromFileName(arg.filename).toLowerCase();
			arg.format = lowFormat;
		}


		switch (arg.format) {
			case "stp": {
				arg.format = "obj";
				this.state.mesh.loadFromSource(arg);
			}
				break;
			case "step": {
				arg.format = "obj";
				this.state.mesh.loadFromSource(arg);
			}
				break;
			case "obj": {
				this.state.mesh.loadFromSource(arg);

			}
				break;
			case "stl": {
				this.state.mesh.loadFromSource(arg);
			}
				break;
			default: {
				console.log("tkk3DEngine : fail to load unsupport format")
			}
				break;
		}
		//arg.loadState.prograss += 0.5 * arg.loadState.ratio;
		arg.loadState.ratio=1;
		arg.loadState.prograss=1.0;
		if (arg.loadState.stop != null && arg.loadState.stop == true)
			return;
		if (arg.loadState.callBackFunction != null)
			arg.loadState.callBackFunction();
		if (this.state.mesh.data?.shapes.length > 0)
		{
			this.generateWebglBuffer(this.state.mesh);
			arg.loadState.prograss += 0.5 * arg.loadState.ratio;
			if (arg.loadState.callBackFunction != null)
				arg.loadState.callBackFunction();
			return true;
		} else
		{
			console.log("tkk3DEngine : fail to load from source , format : " + arg.format);
			return false;
		}

	}

	/*
	*	接口：loadMeshFromFile
	*	parameter：
	*	    file:			文件，是fileSelector读取进来的东西
	*   	loadState：		读取进度，详细信息见 接口 loadMeshFromSource
	*
	*
* */

	loadMeshFromFile(file, loadState) {

		let fr = new FileReader();
		let that = this;
		let arg = {};
		arg.loadState = loadState;
		fr.onload = (r) => {
			arg.filename = file.name;
			arg.format = Util.getSuffixFromFileName(file.name);
			let blob = new Blob([r.currentTarget.result]);
			let dv = new DataView(r.currentTarget.result);
			let number = dv.getInt32(80, true);
			dv = null;

			let totalByte = number * 50 + 4 + 80;
			arg.isBinary = (totalByte == r.total);
			fr.onload = (mr) => {
				arg.meshSource = mr.currentTarget.result;
				blob = null;
				this.state.mesh.clear();
				this.state.mesh = Mesh.createFromSource(arg);
				this.generateWebglBuffer(this.state.mesh);
			}
			if (arg.isBinary) // stl binary
			{
				fr.readAsArrayBuffer(blob);
			} else  //ascii
			{
				fr.readAsText(blob);
			}
		}
		fr.readAsArrayBuffer(file);

	}

	/*
*	接口：loadMeshFromService , 此接口 是给 图哐哐 app 用的接口
*	parameter：
*	    id： 模型ID
* 		token： 用户token
* 		loadState： 详细信息见 接口给 loadMeshFromSource
*
* */
	async loadMeshFromService(id, token = '', loadState) {
		//id='f214f7a270a0571e2cbe226287150d91';
		let arg = {};
		arg.loadState = loadState;
		if (arg.loadState != null) {
			arg.loadState.prograss = 0.0;
			arg.loadState.ratio = 1.0;
			arg.loadState.stop = false;
		} else {
			arg.loadState = {
				process: 0.0,
				ratio: 1.0,
				stop: false,
			}
		}

		if (this.pako == null) {
			this.pako = require("pako");
		}
		let pako = this.pako;
		let that = this;
		var xhr = new XMLHttpRequest();
		xhr.open("get", "http://192.168.110.230:8888/api/download/free?data_id=" + id + "&is_format=1&is_compression=1");

		//xhr.setRequestHeader('Content-Type', 'application/json');
		//xhr.setRequestHeader("Access-Control-Allow-Headers", 'token'); 
		//xhr.setRequestHeader("Access-Control-Expose-Headers", "Content-Disposition,file_name,is_Binary,*");
		xhr.setRequestHeader('token', token);// plus.storage.getItem('token'));

		xhr.responseType = "arraybuffer";

		xhr.send();
		xhr.onreadystatechange = function () {
			if (xhr.readyState == 4) { //监听readyState状态
				if (xhr.status == 200 || xhr.status == 0) { //监听HTTP状态码
					//console.debug("模型数据")
					//console.debug(modelContent);
					// console.debug("请求成功");
					// console.debug("header-Content-Disposition")
					//console.debug( xhr.getResponseHeader("Content-Disposition"));
					console.log("请求成功")
					let fr = new FileReader();
					let allHeader = xhr.getAllResponseHeaders();

					let fn = xhr.getResponseHeader("file_name");

					console.log("base64 filename", fn)
					arg.filename = Base64.decode(fn);
					console.log("header filename:", fn)

					let isBinary = xhr.getResponseHeader("is_binary");
					arg.isBinary = isBinary == '1' ? true : false;
					arg.loadState.prograss += 0.1 * loadState?.ratio;
					if (arg.loadState.callBackFunction != null)
						arg.loadState.callBackFunction();
					// fr.onload=function(result)
					// {
					// 	this.loadMeshFromSource(this.result,fn,isBinary);
					// 	if(isBinary)
					// 	{
					// 		let unzip=pako.inflate(this.result).buffer;
					// 		that.loadMeshFromSource(unzip,fn,isBinary);

					// 	}else
					// 	{
					// 		that.loadMeshFromSource(pako.inflate( this.result,{to:"string"}),fn,isBinary);
					// 	}

					// }
					let oldRatio = arg.loadState.ratio;
					if (arg.loadState.stop == true)
						return;
					arg.loadState.ratio = 0.9
					if (arg.isBinary) {
						//fr.readAsArrayBuffer(pako.inflate( xhr.response).buffer)
						arg.meshSource = pako.inflate(xhr.response).buffer;
						that.loadMeshFromSource(arg)
					} else {
						//fr.readAsText(pako.inflate( xhr.response,{to:"string"}))
						arg.meshSource = pako.inflate(xhr.response, {to: "string"});
						that.loadMeshFromSource(arg)
					}
					loadState.ratio = oldRatio;
					loadState.stop = true;
				}
			}

		}

		xhr.onerror = function (error) {
			console.log("model request error", error)
		}
		// 输出：{"name":"js","age":"18"}
		//loadState.stop=true;
	}
	/*
	* 	初始化引擎函数，此函数为内部自动调用，不需要手动调用
	*
	* */
	initializeEngine() {
		if (this.state.isInitial)
			return;

		let gl = getGlobalWebgl().gl;

		this.state.camera.state.position = [1000, 0, 0];
		this.state.camera.state.direction_up = [0, 1, 0];
		this.state.camera.state.orthogonal.width = 100;
		this.state.camera.state.speed_move = 1;
		this.state.matrixs.view = this.state.camera.getMatrix4X4_view();
		this.state.matrixs.project = this.state.camera.getMatrix4X4_orthogonal();

		this.state.modelControl.setRotateMatrix(this.state.mesh.data.matrice.world_rotate);
		this.state.modelControl.setTranslateMatrix(this.state.mesh.data.matrice.world_translate);
		this.state.modelControl.setViewCamera(this.state.camera, this.state.matrixs.project);

		//this.state.textures.background = gl.createTexture();
		this.state.time.start = new Date().getTime() / 1000.0;
		this.state.renderInfo.lastTimeStamp=this.state.time.start;
		let skySphereImg = new Image;
		let that = this;
		// skySphereImg.onload = function () {
		// 	that.state.renderInfo.needRender=false;
		// 	gl.activeTexture(gl.TEXTURE0);
		// 	gl.bindTexture(gl.TEXTURE_2D,that.state.textures.background);
		// 	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
		// 	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
		// 	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
		// 	gl.texParameteri(
		// 		gl.TEXTURE_2D,
		// 		gl.TEXTURE_MIN_FILTER,
		// 		gl.LINEAR_MIPMAP_LINEAR
		// 	);
		// 	gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);

		// 	gl.texImage2D(gl.TEXTURE_2D,0,gl.RGBA,skySphereImg.naturalWidth,skySphereImg.naturalHeight,0,gl.RGBA,gl.UNSIGNED_BYTE,skySphereImg);

		// 	gl.generateMipmap(gl.TEXTURE_2D)
		// 	gl.bindTexture(gl.TEXTURE_2D,null);
		// 	that.state.renderInfo.needRender=true; 
		// }


		this.render = function () {

			that.state.renderInfo.renderFinished = false;
			let newDate = new Date().getTime() / 1000.0;
			let interval = that.state.renderInfo.interval;
			let resolution = that.state.webglRenderer.getResolution();
			resolution.push(1);
			let cameraDirection = that.state.camera.getLookDirection();
			let gl = that.state.webglRenderer.state.gl;
			gl.enable(gl.DEPTH_TEST);
			gl.depthFunc(gl.LEQUAL);
			gl.clearDepth(2.0);
			gl.clearColor(0, 0, 0, 1);
			gl.enable(gl.DEPTH_TEST);
			gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

			// draw background
			gl.bindVertexArray(null);
			gl.bindBuffer(gl.ARRAY_BUFFER, null);
			gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
			//that.state.pipelines.pipeline_background.use();

			//gl.bindVertexArray(that.state.webglBuffer.vao);
			//gl.bindBuffer(gl.ARRAY_BUFFER,that.state.webglBuffer.vertexBuffer);
			//gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER,that.state.webglBuffer.indexBuffer);
			// gl.activeTexture(gl.TEXTURE0);
			// gl.bindTexture(gl.TEXTURE_2D,that.state.textures.background);
			// that.state.pipelines.pipeline_background.use();
			// that.state.pipelines.pipeline_background.setUniform1f("runTime",interval);
			// that.state.pipelines.pipeline_background.setUniformVec3f("resolution",resolution);
			// that.state.pipelines.pipeline_background.setUniform1i("skySphere",0);
			// that.state.pipelines.pipeline_background.setUniform1i("frameId",that.state.renderInfo.totalFrame);
			// // update camera
			// that.state.pipelines.pipeline_background.setUniformVec3f("camera_position",that.state.camera.state.position);
			// that.state.pipelines.pipeline_background.setUniformVec3f("camera_direction",cameraDirection);
			// that.state.pipelines.pipeline_background.setUniformVec3f("camera_up",that.state.camera.state.direction_up);
			// that.state.pipelines.pipeline_background.setUniform1f("camera_fovH",that.state.camera.state.perspective.fov_H);
			// that.state.pipelines.pipeline_background.setUniform1f("camera_WDH",that.state.camera.state.WDH);

			// gl.drawArrays(gl.TRIANGLE_FAN,0,4);
			//that.state.
			for (let i = 0; i < that.state.renderPass.length; i++) {
				let currentPass = that.state.resource.drawPass[that.state.renderPass[i]];
				currentPass.initializeFunction();
				currentPass.drawFunction();
			}


			that.state.renderInfo.totalFrame += 1;
			that.state.renderInfo.framesPerSecond += 1;
			that.state.renderInfo.renderFinished = true;
		}

		this.clearCanvas = function () {
			that.state.renderInfo.renderFinished = false;
			let newDate = new Date().getTime() / 1000.0;
			let interval = newDate - that.state.time.start;
			let resolution = that.state.webglRenderer.getResolution();
			resolution.push(1);
			let cameraDirection = that.state.camera.getLookDirection();
			let gl = that.state.webglRenderer.state.gl;
			gl.enable(gl.DEPTH_TEST);
			gl.depthFunc(gl.LEQUAL);
			gl.clearDepth(1.0);
			gl.clearColor(0, 0, 0, 1);
			gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

			// draw background
			gl.bindVertexArray(null);
			gl.bindBuffer(gl.ARRAY_BUFFER, null);
			gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
			//that.state.pipelines.pipeline_background.use();

			//gl.bindVertexArray(that.state.webglBuffer.vao);
			//gl.bindBuffer(gl.ARRAY_BUFFER,that.state.webglBuffer.vertexBuffer);
			//gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER,that.state.webglBuffer.indexBuffer);
			// gl.activeTexture(gl.TEXTURE0);
			// gl.bindTexture(gl.TEXTURE_2D,that.state.textures.background);
			// that.state.pipelines.pipeline_background.use();
			// that.state.pipelines.pipeline_background.setUniform1f("runTime",interval);
			// that.state.pipelines.pipeline_background.setUniformVec3f("resolution",resolution);
			// that.state.pipelines.pipeline_background.setUniform1i("skySphere",0);
			// that.state.pipelines.pipeline_background.setUniform1i("frameId",that.state.renderInfo.totalFrame);
			// // update camera
			// that.state.pipelines.pipeline_background.setUniformVec3f("camera_position",that.state.camera.state.position);
			// that.state.pipelines.pipeline_background.setUniformVec3f("camera_direction",cameraDirection);
			// that.state.pipelines.pipeline_background.setUniformVec3f("camera_up",that.state.camera.state.direction_up);
			// that.state.pipelines.pipeline_background.setUniform1f("camera_fovH",that.state.camera.state.perspective.fov_H);
			// that.state.pipelines.pipeline_background.setUniform1f("camera_WDH",that.state.camera.state.WDH);

			// gl.drawArrays(gl.TRIANGLE_FAN,0,4);
			//that.state.
			that.state.mesh.clear();
			that.state.renderInfo.totalFrame += 1;
			that.state.renderInfo.framesPerSecond += 1;
			that.state.renderInfo.renderFinished = true;
		}
		// load pipeline

		this.state.resource.drawPass.defaultBackground_App = {
			isInitialize: false,
			initializeFunction: function () {
				if (!that.state.resource.drawPass.defaultBackground_App.isInitialize) {
					that.state.resource.pipelines.defaultBackground_App = new Pipeline();
					that.state.resource.pipelines.defaultBackground_App.loadVertexShaderFromString(
						`#version 300 es
					out vec2 _uv;
					void main()
					{
						vec2 positions[4];
						positions[0]=vec2(-1.0f,1.0f);
						positions[1]=vec2(-1.0f,-1.0f);
						positions[2]=vec2(1.0f,-1.0f);
						positions[3]=vec2(1.0f,1.0f);
					
						vec2 uvs[4];
						uvs[0]=vec2(0.0f,1.0f);
						uvs[1]=vec2(0.0f,0.0f);
						uvs[2]=vec2(1.0f,0.0f);
						uvs[3]=vec2(1.0f,1.0f);
						_uv=uvs[gl_VertexID];
						gl_Position=vec4(positions[gl_VertexID],1.0f,1.0f);
					}
					`);
					that.state.resource.pipelines.defaultBackground_App.loadFragmentShaderFromString(
						`#version 300 es // double click to fold or expand
					//input
					precision mediump float;
					in vec2 _uv;    // uv of texture
					out vec4 finnalColor;   // output color
						
					//const vec3 top= vec3(0xA5,0xCE,0xFD)/float(0xFF);
					//const vec3 bottom=vec3(0x2F,0x70,0xBA)/float(0xFF);
					const vec3 top= vec3(198.0f,217.0f,227.0f)/float(255);
					const vec3 bottom=vec3(64,96,135)/float(255);
					// const vec3 top= vec3(198.0f,217.0f,227.0f)/float(255);
					// const vec3 bottom=vec3(64,10,20)/float(255);
					void main()
					{
						finnalColor=vec4(mix(bottom,top,vec3(_uv.y)),1.0f);
					}
					
					`);
					that.state.resource.pipelines.defaultBackground_App.compile();
					that.state.resource.drawPass.defaultBackground_App.isInitialize = true;
				}
			},
			drawFunction: function () {
				let gl = getGlobalWebgl().gl;
				gl.bindVertexArray(null);
				gl.bindBuffer(gl.ARRAY_BUFFER, null);
				gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
				that.state.resource.pipelines.defaultBackground_App.use();
				gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
			}
		}

		this.state.resource.drawPass.defaultModel_App = {
			isInitialize: false,
			initializeFunction: function () {
				if (that.state.resource.drawPass.defaultModel_App.isInitialize)
					return;

				that.state.resource.pipelines.defaultModel_App = new Pipeline();
				that.state.resource.pipelines.defaultModel_App.loadVertexShaderFromString(
					`#version 300 es
					
					uniform mat4 matrix_model_boundBox;
					uniform mat4 matrix_model_rotate;
					uniform mat4 matrix_world_rotate;
					uniform mat4 matrix_world_translate;
					uniform mat4 matrix_view;
					uniform mat4 matrix_project;
					uniform vec3 scalar_explode;
					
					uniform displacement
					{
						vec4 position[2000];
					}ubo;
					
					layout(location=0) in vec3 modelPosition;
					layout(location=1) in vec3 modelNormal;
					layout(location=2) in vec4 modelColor;
					layout(location=3) in vec2 modelUV;
					layout(location=4) in float modelId;
					
					out vec4 fragColor;
					out vec3 objectNormal_world;
					out vec3 objectPosition_world;
					
					void main()
					{
						objectNormal_world= normalize(
						  transpose( inverse(mat3(matrix_world_translate*matrix_world_rotate*matrix_model_rotate*matrix_model_boundBox))) *
						modelNormal);
						
						vec4 temP;

						mat4 modelTransorm=matrix_model_rotate*matrix_model_boundBox;
						
						temP=modelTransorm*
						vec4(modelPosition,1.0);
						
						temP+=vec4( (modelTransorm*
						vec4(ubo.position[int( modelId+0.4)].xyz,1.0)
						).xyz*
						scalar_explode,0.0);
						
						temP=matrix_world_translate* 
						matrix_world_rotate*
						temP;
							
						temP/=temP.w;
				
						objectPosition_world= temP.xyz;
						gl_PointSize=10.0;
						gl_Position=
						matrix_project*
						matrix_view*
						temP;

						fragColor=modelColor;
						gl_PointSize=10.0f;
					} 
				`);
				that.state.resource.pipelines.defaultModel_App.loadFragmentShaderFromString(
					`#version 300 es

					precision mediump float;
					uniform vec3 position_camera;
					
					in vec4 fragColor;
					in vec3 objectNormal_world;
					in vec3 objectPosition_world;
					
					out vec4 color;
					
					struct Light_Parallel
					{
						vec3 color;
						vec3 direction;
					}light_parallel[1];
					
					struct Light_Point
					{
						vec3 color;
						float intensity;
						vec3 position;
						float attenuation;
					}light_point[1];
					
					vec3 light_ambient=vec3(0.2);
					vec3 calculateColor_ambient()
					{
						return vec3(fragColor)*light_ambient;
					}
					
					vec3 calculateColor_Light_Diffuse()
					{
						vec3 result=vec3(0,0,0);
						// for(int i=0;i<light_point.length();i++)
						// {
						// 	float intensity=max( 0.0,light_point[i].intensity
						// 	*pow (2.718281828459045,-light_point[i].attenuation*
						// 	length(light_point[i].position-objectPosition_world))
						// 	);
						// 	result+=max(0.0, -dot( normalize(objectNormal_world),normalize(light_point[i].position-objectPosition_world)))
						// 	*intensity*fragColor.xyz*
						// 	light_point[i].color;
						// }
						
						// parallel light
						for(int i=0;i<light_parallel.length();i++)
						{
							result+=max(0.0, -dot( normalize(objectNormal_world),light_parallel[i].direction))
							*fragColor.xyz*
							light_parallel[i].color;
						}
						return result;
					}
					
					vec3 calculateColor_Light_Specular()
					{
						vec3 result=vec3(0,0,0);
						
						// for(int i=0;i<light_point.length();i++)
						// {
						// 	vec3 lightDirection= normalize( objectPosition_world-light_point[i].position);
						// 	float intensity;
						// 	if(dot(lightDirection,objectNormal_world)>=0.0)
						// 	  continue;
							
						// 	intensity = max (0.0,light_point[i].intensity
						// 	*pow(2.718281828459045,-light_point[i].attenuation*length(light_point[i].position-objectPosition_world))*2.0
						// 	)* pow(max(-dot(
						// 	normalize( objectPosition_world-position_camera),
						// 	normalize( reflect(lightDirection,objectNormal_world) )),0.0),60.0);
							
						// 	result+=intensity* light_point[i].color*light_point[i].intensity;
						// }
						
						
						for(int i=0;i<light_parallel.length();i++)
						{
								float intensity;
								if(dot(light_parallel[i].direction,objectNormal_world)>=0.0)
								  continue;
								
								intensity =  pow(max(-dot(
								light_parallel[i].direction,
								normalize( reflect(light_parallel[i].direction,objectNormal_world) )),0.0),3.0);
								
								result+=intensity* light_parallel[i].color*fragColor.xyz;
						}
						return result;
					}
					void main() 
					{
						// light_point[0].color= vec3(1,1,1);
						// light_point[0].intensity=1.0;
						// light_point[0].position=vec3(500.0f,0.0f,0.0f);//normalize(position_camera)*500.0f;
						// light_point[0].attenuation=0.0005;
						//设置颜色
						light_parallel[0].color=vec3(1,1,1);
						light_parallel[0].direction= -normalize(position_camera);
							
							
						color = vec4((calculateColor_ambient()
						+calculateColor_Light_Diffuse()
						)*0.8f
						+calculateColor_Light_Specular()*0.9f
						,1.0);
					}
				`);
				that.state.resource.pipelines.defaultModel_App.compile();
				that.state.resource.pipelines.defaultModel_App.setUniformBlockBinding("displacement", 0);

				if (that.state.resource.drawPass.defaultModel_App.explodeScalar == null) {
					that.state.resource.drawPass.defaultModel_App.explodeScalar = new Float32Array([0, 0, 0]);
				}

				that.state.resource.attributeFunction.set.explodeScalar = function (arg) {
					console.log(this)
					if (arg.x != null) {
						that.state.resource.drawPass.defaultModel_App.explodeScalar[0] = arg.x;
					}
					if (arg.y != null) {
						that.state.resource.drawPass.defaultModel_App.explodeScalar[1] = arg.y;
					}
					if (arg.z != null) {
						that.state.resource.drawPass.defaultModel_App.explodeScalar[2] = arg.z;
					}
				}

				that.state.resource.attributeFunction.get.explodeScalar = function (arg) {
					return that.state.resource.drawPass.defaultModel_App.explodeScalar;
				}

				that.state.resource.drawPass.defaultModel_App.isInitialize = true;
			},
			drawFunction: function () {
				let temShape;
				let stack = [];
				for (let i = 0; i < that.state.mesh.data.shapes.length; i++)
					stack.push(that.state.mesh.data.shapes[i]);
				while (stack.length) {
					temShape = stack.pop();
					if (temShape.data.webgl.drawNumber > 0) {

						gl.bindVertexArray(temShape.data.webgl.vao);
						that.state.resource.pipelines.defaultModel_App.use();
						that.state.resource.pipelines.defaultModel_App.setUniformMatrix4f("matrix_model_boundBox", that.state.mesh.data.matrice.model_translate.data);
						//that.state.resource.pipelines.defaultModel.setUniformMatrix4f("matrix_world_rotate",that.state.matrixs.world_rotate.data);
						that.state.resource.pipelines.defaultModel_App.setUniformMatrix4f("matrix_world_rotate", that.state.mesh.data.matrice.world_rotate.data);
						that.state.resource.pipelines.defaultModel_App.setUniformMatrix4f("matrix_world_translate", that.state.mesh.data.matrice.world_translate.data);
						that.state.resource.pipelines.defaultModel_App.setUniformMatrix4f("matrix_view", that.state.matrixs.view.data);
						that.state.resource.pipelines.defaultModel_App.setUniformMatrix4f("matrix_project", that.state.matrixs.project.data);
						that.state.resource.pipelines.defaultModel_App.setUniformVec3f("position_camera", new Float32Array(that.state.camera.state.position));
						that.state.resource.pipelines.defaultModel_App.setUniformVec3f("scalar_explode", that.state.resource.drawPass.defaultModel_App.explodeScalar);
						gl.drawArrays(gl.TRIANGLES, 0, temShape.data.webgl.drawNumber);

					}

					for (let i = 0; i < temShape.children.length; i++) {
						stack.push(temShape.children[i]);
					}

					temShape = null;
				}
			},


		}


		this.state.resource.drawPass.defaultBackground_Website = {
			isInitialize: false,
			initializeFunction: function () {
				if (!that.state.resource.drawPass.defaultBackground_Website.isInitialize) {
					that.state.resource.pipelines.defaultBackground_Website = new Pipeline();
					that.state.resource.pipelines.defaultBackground_Website.loadVertexShaderFromString(
						`#version 300 es
					out vec2 _uv;
					void main()
					{
						vec2 positions[4];
						positions[0]=vec2(-1.0f,1.0f);
						positions[1]=vec2(-1.0f,-1.0f);
						positions[2]=vec2(1.0f,-1.0f);
						positions[3]=vec2(1.0f,1.0f);
					
						vec2 uvs[4];
						uvs[0]=vec2(0.0f,1.0f);
						uvs[1]=vec2(0.0f,0.0f);
						uvs[2]=vec2(1.0f,0.0f);
						uvs[3]=vec2(1.0f,1.0f);
						_uv=uvs[gl_VertexID];
						gl_Position=vec4(positions[gl_VertexID],1.0f,1.0f);
					}
					`);
					that.state.resource.pipelines.defaultBackground_Website.loadFragmentShaderFromString(
						`#version 300 es // double click to fold or expand
					//input
					precision mediump float;
					in vec2 _uv;    // uv of texture
					out vec4 finnalColor;   // output color
						
					const vec3 top= vec3(0x80,0x80,0x80)/float(0xFF);
					const vec3 bottom=vec3(0x03,0x03,0x03)/float(0xFF);
												// // blue
												// const vec3 top= vec3(198.0f,217.0f,227.0f)/float(255);
												// const vec3 bottom=vec3(64,96,135)/float(255);
					// const vec3 top= vec3(198.0f,217.0f,227.0f)/float(255);
					// const vec3 bottom=vec3(64,10,20)/float(255);
					void main()
					{
						finnalColor= vec4(mix(bottom,top,vec3(_uv.y)),1.0f);
						finnalColor=vec4(0x41,0x41,0x4f,1.0)/vec4(vec3(255),1.0);
						
					}
					
					`);
					that.state.resource.pipelines.defaultBackground_Website.compile();
					that.state.resource.drawPass.defaultBackground_Website.isInitialize = true;
				}
			},
			drawFunction: function () {
				let gl = getGlobalWebgl().gl;
				gl.bindVertexArray(null);
				gl.bindBuffer(gl.ARRAY_BUFFER, null);
				gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
				that.state.resource.pipelines.defaultBackground_Website.use();
				gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
			}
		}

		this.state.resource.drawPass.defaultModel_Website = {
			isInitialize: false,
			initializeFunction: function () {
				if (that.state.resource.drawPass.defaultModel_Website.isInitialize)
					return;

				that.state.resource.pipelines.defaultModel_Website = new Pipeline();
				that.state.resource.pipelines.defaultModel_Website.loadVertexShaderFromString(
					`#version 300 es
					
					uniform mat4 matrix_model_boundBox;
					uniform mat4 matrix_model_rotate;
					uniform mat4 matrix_world_rotate;
					uniform mat4 matrix_world_translate;
					uniform mat4 matrix_view;
					uniform mat4 matrix_project;
					uniform vec3 scalar_explode;
					
					uniform displacement
					{
						vec4 position[2000];
					}ubo;
					
					layout(location=0) in vec3 modelPosition;
					layout(location=1) in vec3 modelNormal;
					layout(location=2) in vec4 modelColor;
					layout(location=3) in vec2 modelUV;
					layout(location=4) in float modelId;
					
					out vec4 fragColor;
					out vec3 objectNormal_world;
					out vec3 objectPosition_world;
					
					void main()
					{
						objectNormal_world= normalize(
						  transpose( inverse(mat3(matrix_world_translate*matrix_world_rotate*matrix_model_rotate*matrix_model_boundBox))) *
						modelNormal );
						
						vec4 temP;

						mat4 modelTransorm = matrix_model_rotate * matrix_model_boundBox ;
						
						temP = modelTransorm *
						vec4(modelPosition,1.0);
						
						temP += vec4 ( (
						modelTransorm *
						ubo.position [ int ( modelId+0.2) ] ).xyz * scalar_explode , 0.0 ) ;
						
						temP=matrix_world_translate* 
						matrix_world_rotate*
						temP;
							
						temP/=temP.w;
				
						objectPosition_world = temP.xyz ;
						gl_PointSize = 10.0 ;
						gl_Position =
						matrix_project *
						matrix_view *
						temP ;
						
						fragColor=modelColor;
						gl_PointSize = 10.0f ;
					} 
				`);
				that.state.resource.pipelines.defaultModel_Website.loadFragmentShaderFromString(
					`#version 300 es

					precision mediump float;
					uniform vec3 position_camera;
					
					in vec4 fragColor;
					in vec3 objectNormal_world;
					in vec3 objectPosition_world;
					
					out vec4 color;
					
					struct Light_Parallel
					{
						vec3 color;
						vec3 direction;
					}light_parallel[1];
					
					struct Light_Point
					{
						vec3 color;
						float intensity;
						vec3 position;
						float attenuation;
					}light_point[1];
					
					vec3 light_ambient=vec3(0.2);
					vec3 calculateColor_ambient()
					{
						return vec3(fragColor)*light_ambient;
					}
					
					vec3 calculateColor_Light_Diffuse()
					{
						vec3 result=vec3(0,0,0);
						// for(int i=0;i<light_point.length();i++)
						// {
						// 	float intensity=max( 0.0,light_point[i].intensity
						// 	*pow (2.718281828459045,-light_point[i].attenuation*
						// 	length(light_point[i].position-objectPosition_world))
						// 	);
						// 	result+=max(0.0, -dot( normalize(objectNormal_world),normalize(light_point[i].position-objectPosition_world)))
						// 	*intensity*fragColor.xyz*
						// 	light_point[i].color;
						// }
						
						// parallel light
						for(int i=0;i<light_parallel.length();i++)
						{
							result+=max(0.0, -dot( normalize(objectNormal_world),light_parallel[i].direction))
							*fragColor.xyz*
							light_parallel[i].color;
						}
						return result;
					}
					
					vec3 calculateColor_Light_Specular()
					{
						vec3 result=vec3(0,0,0);
						
						// for(int i=0;i<light_point.length();i++)
						// {
						// 	vec3 lightDirection= normalize( objectPosition_world-light_point[i].position);
						// 	float intensity;
						// 	if(dot(lightDirection,objectNormal_world)>=0.0)
						// 	  continue;
							
						// 	intensity = max (0.0,light_point[i].intensity
						// 	*pow(2.718281828459045,-light_point[i].attenuation*length(light_point[i].position-objectPosition_world))*2.0
						// 	)* pow(max(-dot(
						// 	normalize( objectPosition_world-position_camera),
						// 	normalize( reflect(lightDirection,objectNormal_world) )),0.0),60.0);
							
						// 	result+=intensity* light_point[i].color*light_point[i].intensity;
						// }
						
						
						for(int i=0;i<light_parallel.length();i++)
						{
								float intensity;
								if(dot(light_parallel[i].direction,objectNormal_world)>=0.0)
								  continue;
								
								intensity =  pow(max(-dot(
								light_parallel[i].direction,
								normalize( reflect(light_parallel[i].direction,objectNormal_world) )),0.0),3.0);
								
								result+=intensity* light_parallel[i].color*fragColor.xyz;
						}
						return result;
					}
					void main() 
					{
						// light_point[0].color= vec3(1,1,1);
						// light_point[0].intensity=1.0;
						// light_point[0].position=vec3(500.0f,0.0f,0.0f);//normalize(position_camera)*500.0f;
						// light_point[0].attenuation=0.0005;
						//设置颜色
						light_parallel[0].color=vec3(1,1,1);
						light_parallel[0].direction= -normalize(position_camera);
							
							
						color = vec4((calculateColor_ambient()
						+calculateColor_Light_Diffuse()
						)*0.8f
						+calculateColor_Light_Specular()*0.9f
						,1.0);
					}
				`);
				that.state.resource.pipelines.defaultModel_Website.compile();
				that.state.resource.pipelines.defaultModel_Website.setUniformBlockBinding("displacement", 0);

				if (that.state.resource.drawPass.defaultModel_Website.explodeScalar == null) {
					that.state.resource.drawPass.defaultModel_Website.explodeScalar = new Float32Array([0, 0, 0]);
				}


				that.state.resource.attributeFunction.set.explodeScalar = function (arg) {
					if (arg.x != null) {
						that.state.resource.drawPass.defaultModel_Website.explodeScalar[0] = arg.x;
					}
					if (arg.y != null) {
						that.state.resource.drawPass.defaultModel_Website.explodeScalar[1] = arg.y;
					}
					if (arg.z != null) {
						that.state.resource.drawPass.defaultModel_Website.explodeScalar[2] = arg.z;
					}
				}

				that.state.resource.attributeFunction.get.explodeScalar = function (arg) {
					return that.state.resource.drawPass.defaultModel_Website.explodeScalar;
				}


				that.state.resource.drawPass.defaultModel_Website.isInitialize = true;
			},
			drawFunction: function () {
				let temShape;
				let stack = [];
				for (let i = 0; i < that.state.mesh.data.shapes.length; i++)
					stack.push(that.state.mesh.data.shapes[i]);
				that.state.resource.pipelines.defaultModel_Website.use();
				while (stack.length) {
					temShape = stack.pop();
					if (temShape.data.webgl.drawNumber > 0) {
						gl.bindVertexArray(temShape.data.webgl.vao);
						gl.bindBufferBase(gl.UNIFORM_BUFFER, 0, temShape.data.webgl.displacementBuffer);
						that.state.resource.pipelines.defaultModel_Website.setUniformMatrix4f("matrix_model_boundBox", that.state.mesh.data.matrice.model_translate.data);
						that.state.resource.pipelines.defaultModel_Website.setUniformMatrix4f("matrix_model_rotate", that.state.mesh.data.matrice.model_rotate.data);
						that.state.resource.pipelines.defaultModel_Website.setUniformMatrix4f("matrix_world_rotate", that.state.mesh.data.matrice.world_rotate.data);
						that.state.resource.pipelines.defaultModel_Website.setUniformMatrix4f("matrix_world_translate", that.state.mesh.data.matrice.world_translate.data);
						that.state.resource.pipelines.defaultModel_Website.setUniformMatrix4f("matrix_view", that.state.matrixs.view.data);
						that.state.resource.pipelines.defaultModel_Website.setUniformMatrix4f("matrix_project", that.state.matrixs.project.data);
						that.state.resource.pipelines.defaultModel_Website.setUniformVec3f("position_camera", new Float32Array(that.state.camera.state.position));
						that.state.resource.pipelines.defaultModel_Website.setUniformVec3f("scalar_explode", that.state.resource.drawPass.defaultModel_Website.explodeScalar);
						gl.drawArrays(gl.TRIANGLES, 0, temShape.data.webgl.drawNumber);
						gl.bindVertexArray(null);
					}
					temShape = null;
				}
			},


		}

		// initialize the setAttribute Function


		this.state.resource.attributeFunction.set.renderPass = function (arg) {
			if (Array.isArray(arg)) {
				that.state.renderPass = arg;
			}
		}

		this.state.resource.attributeFunction.set.enableWheel = function (isEnable) {
			if (typeof isEnable == 'boolean' ) {
				that.state.modelControl.state.control.enableWheel = isEnable;
			}
		}

		this.state.resource.attributeFunction.set.targetCanvas = function (canvas) {
			that.setTargetCanvas(canvas);
		}
		//this.state.renderPass=["defaultBackground_App","defaultModel_App"];

		this.state.resource.attributeFunction.set.enableAutoRotate=function (isEnable)
		{
			if( typeof isEnable == 'boolean' )
			{
				that.state.functional.autoRotate.enable=isEnable;
			}
		}

		this.state.resource.attributeFunction.get.enableAutoRotate=function ()
		{
			return that.state.functional.autoRotate.enable;
		}

		this.state.resource.attributeFunction.set.autoRotateSpeed=function (speed)
		{
			if( typeof speed == 'number')
			{
				that.state.functional.autoRotate.speed=speed;
			}
		}

		this.state.resource.attributeFunction.get.autoRotateSpeed=function (isEnable)
		{
			return that.state.functional.autoRotate.speed;
		}

		this.state.resource.attributeFunction.set.autoRotateAxes=function (xyz)
		{
			if(Array.isArray(xyz))
			{
				let length=xyz[0]*xyz[0]+xyz[1]*xyz[1]+xyz[2]*xyz[2];
				if(length!=0)
				{
					length=Math.sqrt(length);
					that.state.functional.autoRotate.rotateAxes=[xyz[0]/length,xyz[1]/length,xyz[2]/length];
				}
			}


		}

		this.state.resource.attributeFunction.get.autoRotateAxes=function ()
		{
			return that.state.functional.autoRotate.rotateAxes;
		}

		this.state.resource.attributeFunction.set.model=function (arg)
		{

		/*
		*	 	 	arg ： 要求传入一个对象，该对象的格式为
		* 			arg={
		*	 				format:			文件格式，要求小写，例如"obj","stl"等等。
		*	 				filename:		文件名称，例如"123.obj","dragon.stl"。
		*	 				isBinary:		文件是否为二进制，true 或者 false ， stl 有 二进制 和 ascii 两种格式。
		*	 				meshSource:		模型源文件，如果 isBinary 为 true ，需要传入 string 。如果 isBinary 为 false ，需要传入 arrayBuffer。
		*	 				loadState:{		这时回调事件，如果模型加载到一定程度会传值回去
		* 								ratio:				此次任务在总任务占的比重，正常为1，如果要指派子任务函数，子任务相当于总工作量的30%，将ratio设为0.3，子工作完成时会将prograss+=ratio*1
		* 								prograss:			模型加载进度，0到1之间，为浮点数，0为未加载，1为加载完毕。
		* 								callBackfuntion: 	回调函数，如果加载进度更新，会调用这个函数
		* 						  	}
		* 				}
		*
		*
		*/


			if(typeof (arg)=="object")
			{
				if(arg.meshSource==null)
				{
					console.error("arg.meshSource must be a valid mesh data in type of string/ArrayBuffer");
					return;
				}
				if(arg.filename==null)
				{
					console.error("arg.filename must be a valid data in type of string ");
					return false;
				}
				if(arg.isBinary==null)
				{
					arg.isBinary=false;
				}
				if(arg.loadState==null)
				{
					arg.loadState={
						ratio:1,
						process: 0,
						callBackFunction: function(){console.log("Load Mesh Status: ",process);},
					}
				}
				that.loadMeshFromSource(arg);
			}
		}

		this.state.resource.attributeFunction.set.move=function (direction,length)
		{
			if(typeof direction=='string')
			{
				if(length==null||typeof length!='number')
				{
					length=that.state.camera.state.orthogonal.width/15;
				}
				switch (direction)
				{
					case "front":{
						that.state.camera.move("front",length);
						that.state.matrixs.project.data=that.state.camera.getMatrix4X4_orthogonal().data;
					}break;
					case "back":{
						that.state.camera.move("back",length);
						that.state.matrixs.project.data=that.state.camera.getMatrix4X4_orthogonal().data;
					}break;
					default:{
						console.log("please set move direction correctly (front of back)");
					}break;
				}
			}

		}
		// end initial
		this.state.isInitial = true;
		console.log("successful to initialize engine");
	}

	/*
	* 	接口： getAttribute ， 用来获取系统状态
	* 	parameter：
	* 		name： string，获取的状态名称
	*
	* 	列举用法：
	* 		getAttribute("explodeScalar") => Float32Array(3);
	*
	*
	*
	* */

	getAttribute(name) {
		if (typeof name !== 'string')
			return;
		if (this.state.resource.attributeFunction.get[name] != null) {
			return this.state.resource.attributeFunction.get[name]();
		}
	}

	/*
	* 	接口： setAttribute ， 用来设置属性
	* 	paramete：
	* 		name： 属性的名称
	* 		arg： 	赋予属性的值
	*
	* 	列举用法：
	* 		setAttribute("renderPass",["defaultBackground_App","defaultModel_App"])         // 设置绘制流程	defaultBackground_Website,defaultModel_Website
	* 		setAttribute("enableWheel",true)												//	设置是否启用滚轮放大缩小模型
	* 		setAttribute("explodeScalar,{x:0.5,y:null,z:null}								//	设置爆炸图xyz各个方向的比例，0为不爆炸，数值越大爆炸距离越远
	*		setAttribute("enableAutoRotate",true);											//	设置是否启用自动旋转
	* 		setAttribute("autoRotateSpeed",3.0);											//	设置自动旋转时的速度
	* 		setAttribute("autoRotateAxes",[0,1,0])											//	设置自动旋转时绕的轴
	* 		setAttribute("model",arg)														//	读取模型,传入参数如下所示
	*																				 	 			arg ： 要求传入一个对象，该对象的格式为
	*																				 				arg={
	*																					 					format:			文件格式，要求小写，例如"obj","stl"等等。
	*																					 					filename:		文件名称，例如"123.obj","dragon.stl"。
	*																					 					isBinary:		文件是否为二进制，true 或者 false ， stl 有 二进制 和 ascii 两种格式。
	*																					 					meshSource:		模型源文件，如果 isBinary 为 true ，需要传入 string 。如果 isBinary 为 false ，需要传入 arrayBuffer。
	*																					 					loadState:{		这时回调事件，如果模型加载到一定程度会传值回去
	*																				 									ratio:				此次任务在总任务占的比重，正常为1，如果要指派子任务函数，子任务相当于总工作量的30%，将ratio设为0.3，子工作完成时会将prograss+=ratio*1
	*																				 									prograss:			模型加载进度，0到1之间，为浮点数，0为未加载，1为加载完毕。
	*																				 									callBackfuntion: 	回调函数，如果加载进度更新，会调用这个函数
	*																				 							  	}
	*																				 					}
	*
	* 		setAttribute("move","front"/"back" [,length])										// 放大缩小模型，front 放大，back缩小，第三个参数可选代表模型放大缩小的距离，如果不设置会使用自适应距离。
	*
	*
	*
	* */

	setAttribute(name, arg1,arg2)
	{
		if (typeof name !== 'string')
			return;
		if (this.state.resource.attributeFunction.set[name] != null) {
			this.state.resource.attributeFunction.set[name](arg1,arg2);
		}
	}

	// 此接口未完成
	loadBigMeshFromSource(source, format, isBinary = false) {

		// load with worker
		// if(typeof(Worker)==="function"&& window!=null)
		// {
		// 	if(this.state.workers.loadModel!=null)
		// 	{
		// 		this.state.workers.loadModel.terminate();
		// 		this.state.workers.loadModel=null;
		// 	}
		//
		// 	let workerFunction=function()
		// 	{
		// 		onmessage=function(e)
		// 		{
		// 			console.log("worker执行开始，参数：",e.data);
		// 			let vertices=[];
		// 			let normals=[];
		// 			let colors=[];
		//
		// 			let lowFormat=Util.getSuffixFromFileName(arg.filename).toLowerCase();
		// 			switch(lowFormat)
		// 			{
		// 				case "stp":
		// 				{
		//
		// 				}
		// 				case "step":
		// 				{
		//
		// 				}
		// 				case "obj":
		// 				{
		//
		// 					if(loadState==null)
		// 					{
		// 						loadState={
		// 							prograss:0,
		// 							ratio:1,
		// 							stop:false,
		// 						}
		// 					}else
		// 					{
		// 						if(loadState.stop==null)
		// 							loadState.stop=false;
		// 					}
		// 					let lines=objText.split('\n');
		// 					let parse=new StringParser();
		// 					let result=new Mesh();
		// 					let index=0;
		// 					let temColor=new Vector([1,1,1,1]);
		// 					result.meshData.format="obj";
		// 					result.meshData.mesh.colors.push(temColor);
		// 					result.meshData.shapes.push(new Shape());
		// 					for(let index=0;index<lines.length&&loadState.stop==false;index++)
		// 					{
		// 					    parse.setContent(lines[index]);
		// 					    let fs=parse.getNextVisibleString();
		//
		// 					    switch (fs)
		// 					    {
		// 					        case "v": {
		// 					            let v=new  Vector(3);
		// 					            v.data[0]=parse.getNextFloat();
		// 					            v.data[1]=parse.getNextFloat();
		// 					            v.data[2]=parse.getNextFloat();
		//
		// 					            result.meshData.mesh.vertice.push(v);
		// 					            result.meshData.boundBox.addPoint3_vec3(v);
		// 					            break;
		// 					        }
		// 					        case "vt":{
		// 					            let t=new  Vector(2);
		// 					            t.data[0]=parse.getNextFloat();
		// 					            t.data[1]=parse.getNextFloat();
		// 					            result.meshData.material.uvs.push(t);
		// 					            break;
		// 					        }
		// 					        case "vn":{
		// 					            let n=new  Vector(3);
		// 					            n.data[0]=parse.getNextFloat();
		// 					            n.data[1]=parse.getNextFloat();
		// 					            n.data[2]=parse.getNextFloat();
		// 					            result.meshData.mesh.normals.push(n);
		// 					            break;
		// 					        }
		// 					        case "vc":{
		// 					            let c=new Vector(4);
		// 					            c.data[0]=parse.getNextFloat();
		// 					            c.data[1]=parse.getNextFloat();
		// 					            c.data[2]=parse.getNextFloat();
		// 					            c.data[3]=1;
		// 					            result.meshData.mesh.colors.push(c);
		// 					            break;
		// 					        }
		// 					        case "g": {
		// 					            if(result.meshData.shapes[result.meshData.shapes.length-1].faces.length!=0) {
		// 					                result.meshData.shapes.push(new Shape());
		// 					            }
		// 					            break;
		// 					        }
		// 					        case "f":{
		// 					            let face=new Face();
		// 					            while(parse.hasNextUInt())
		// 					            {
		// 					                let point=new Point();
		// 					                let eachUnit= parse.getNextVisibleString().split('/');
		// 					                point.index_vertex=parseInt(eachUnit[0])-1;
		// 					                if(eachUnit[1].length>0)
		// 					                    point.index_uv=parseInt(eachUnit[1])-1;
		// 					                else
		// 					                    point.index_uv=0;
		// 					                if(eachUnit.length>2&& eachUnit[2].length>0)
		// 					                    point.index_normal=parseInt(eachUnit[2])-1;
		// 					                else
		// 									{
		// 										//result.meshData.mesh.normals.push(-1)
		// 										point.index_normal=-1;
		// 									}
		//
		// 					                if(eachUnit.length>3&&eachUnit[3].length>0)
		// 					                    point.index_color=parseInt(eachUnit[3]);
		// 					                else
		// 					                    point.index_color=0;
		// 					                face.points.push(point);
		// 					            }
		// 					            result.meshData.shapes[result.meshData.shapes.length-1].faces.push(face);
		// 					            break;
		// 					        }
		// 					        case "mtllib":break;
		// 					        case "usemtl":break;
		// 					    }
		//
		// 					}
		//
		// 				}break;
		// 				case "stl":
		// 				{
		// 					newMesh.loadFromSource(source,"stl",isBinary,loadState);
		// 				}break;
		// 				default:
		// 				{
		// 					console.log("tkk3DEngine : fail to load unsupport format")
		// 				}break;
		// 			};
		// 			loadState.prograss+=0.5*loadState.ratio;
		// 			if(loadState.stop!=null &&loadState.stop==true)
		// 				return;
		// 			if(loadState.callBackFunction!=null)
		// 				loadState.callBackFunction();
		// 			if(newMesh.meshData.shapes.length>0)
		// 			{
		//
		// 				this.state.mesh=newMesh;
		// 				this.generateWebglBuffer();
		// 				loadState.prograss+=0.5*loadState.ratio;
		// 				if(loadState.callBackFunction!=null)
		// 					loadState.callBackFunction();
		// 				return true;
		// 			}else
		// 			{
		// 				console.log("tkk3DEngine : fail to load from source , format : "+lowFormat);
		// 				return false;
		// 			}
		//
		//
		// 			console.log("worker执行结束")
		// 			postMessage("123");
		// 		}
		// 	}
		//
		// 	this.state.workers.loadModel=new Worker(URL.createObjectURL (new Blob ([`(${workerFunction.toString ()})()`])));
		// 	//this.state.workers.loadModel.postMessage("123");
		// 	this.state.workers.loadModel.onmessage=(e)=>{
		// 		console.log("主线程接收到子线程信息",e);
		// 	}
		//
		// }else // load without worker
		// {
		//
		// }


	}
	/*此函数为内部调用，不需要手动调用*/
	update()
	{
		let interval=this.state.renderInfo.interval;
		if(this.state.functional.autoRotate.enable&&this.state.mesh.data!=null)
		{
			this.state.mesh.data.matrice.world_rotate.data= Matrix.multiply( this.state.mesh.data.matrice.world_rotate,Matrix.getRotate_angle_4X4_array(this.state.functional.autoRotate.speed*interval,this.state.functional.autoRotate.rotateAxes)).data;
		}
	}
}

