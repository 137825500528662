import {Vector,Matrix} from "./Math.js"

export {Controller}

    class Controller
    {
        constructor() {
            this.state={
                targetCanvas:null,
                matrices:{
                    rotate:null,
                    translate:null,
                    orthogonal:null,
					project:null,
                },
                camera:null,
                info:{
                    lastTouchPosition:{
                        x:[0,0,0,0],
                        y:[0,0,0,0],
                    },
                    lastTouchDistance_2:0,
                    lastTouchMiddle_2:{
                        x:0,
                        y:0,
                    },
                    lastTouchRadian_2:0,


                },
                control: {
                    enableWheel:false,
                },

            }

        }


        setTargetCanvas(canvas)
        {
            if(canvas==null||canvas.nodeName==null||canvas.nodeName!='CANVAS')
            {
                console.log("LBController: please set targetCanvas correctly");
                return;
            }
            else
            {
                this.state.targetCanvas=canvas;
            }
        }

        setRotateMatrix(matrix) {
            this.state.matrices.rotate = matrix;
        }
        setTranslateMatrix(matrix)
        {
            this.state.matrices.translate=matrix;
        }

        setViewCamera(camera,projectionMatrix)
        {
            if(camera?.getObjectName()!="LBCamera3D")
            {
                console.log("LBController: please set view camera correctly");
                return;
            }
            this.state.camera=camera;
            this.state.matrices.orthogonal=projectionMatrix;
        }


        update() {

            let that =this;
            let canvas = this.state.targetCanvas;
            let hasRotateMatrix=false;
            let hasTranslateMatrix=false;
            let hasCamera=false;

            if (canvas == null || canvas.nodeName == null || canvas.nodeName != 'CANVAS') {
                console.log("LBController: please set targetCanvas correctly");
                return;
            }else
            {
                canvas.oncontextmenu=function (event){return false;};
            }
            if (this.state.matrices.rotate == null) {
                console.log("LBController: rotate matrix not set");
                hasRotateMatrix=false;
            }
            else {
                hasRotateMatrix=true;
            }

            if (this.state.matrices.translate == null) {
                console.log("LBController: rotate matrix not set");
                hasTranslateMatrix=false;
            }
            else {
                hasTranslateMatrix=true;
            }

            if(!hasCamera&&!hasRotateMatrix&&!hasTranslateMatrix)
            {
                console.log("LBController: must set view camera or model matrix");
                return;
            }

            if(this.state.camera==null)
            {
                console.log("LBController: view camera not set");
                hasCamera=false;
            }else {
                hasCamera = true;
            }
            canvas.addEventListener("mousedown", function (event) {
				that.mouseDown(event,that);
            }, false);
            canvas.addEventListener("touchstart", function (event) {
                that.touchStart(event,that);
            }, {passive:true});

            canvas.addEventListener("mousemove", function (event) {
				that.mouseMove(event,that)
            })
            canvas.addEventListener("touchmove", function (event)
            {
                that.touchMove(event,that);
                event.preventDefault();
            }, {passive:true});

             canvas.addEventListener("wheel", function (event) {
                 if(that.state.control.enableWheel)
                 {
                     that.wheel(event,that);
                     event.preventDefault();
                 }

                 
             }, false);

        }

        disconnect()
        {

        }
        wheel(event,that)
        {
            
            if(event.wheelDeltaY>0) // up roll
            {
                if(that.state.camera==null)
                {
                    return;
                }
				let scaleCoefficient = that.state.camera.state.orthogonal.width/20;
                that.state.camera.move("front",scaleCoefficient);
				this.state.matrices.orthogonal.data=this.state.camera.getMatrix4X4_orthogonal().data;
            }else if(event.wheelDeltaY<0)   // down roll
            {
                if(that.state.camera==null)
                {
                    return;
                }
				let scaleCoefficient = that.state.camera.state.orthogonal.width/20;
                that.state.camera.move("back",scaleCoefficient);
				this.state.matrices.orthogonal.data=this.state.camera.getMatrix4X4_orthogonal().data;
            }
            //event.preventDefault();
        }
        mouseDown(event,that)
        {
			switch(event.buttons)
			{
				case 1:{
					that.state.info.lastTouchPosition.x[0] = event.clientX;
					that.state.info.lastTouchPosition.y[0] = event.clientY;
				}break;
				case 2:{

					that.state.info.lastTouchMiddle_2.x = event.clientX;
					that.state.info.lastTouchMiddle_2.y = event.clientY;
				}break;
				case 4:{
					

				}break;
			}

        }
        mouseMove(event,that)
        {
			let temMatrix;
			switch(event.buttons)
			{
				
				case 1:{
		
					let intervalX = event.clientX - that.state.info.lastTouchPosition.x[0];
					let intervalY = event.clientY - that.state.info.lastTouchPosition.y[0];
					let moveAngle = 1.4;
					// y movement
					if (intervalY > 0) {
						temMatrix =  Matrix.getRotate_angle_Z(-moveAngle);
						that.state.matrices.rotate.data =  Matrix.multiply(temMatrix, that.state.matrices.rotate).data;
					
					} else if (intervalY < 0) {
						temMatrix =  Matrix.getRotate_angle_Z(moveAngle);
						that.state.matrices.rotate.data =  Matrix.multiply(temMatrix, that.state.matrices.rotate).data;
					}
					// x movement
					if (intervalX > 0) {
						temMatrix =  Matrix.getRotate_angle_Y(moveAngle);
						that.state.matrices.rotate.data =  Matrix.multiply(temMatrix, that.state.matrices.rotate).data;
					
					} else if (intervalX < 0) {
						temMatrix =  Matrix.getRotate_angle_Y(-moveAngle);
						that.state.matrices.rotate.data =  Matrix.multiply(temMatrix, that.state.matrices.rotate).data;
					}
					that.state.info.lastTouchPosition.x[0] = event.clientX;
					that.state.info.lastTouchPosition.y[0] = event.clientY;
				}break;
				case 2:{

					let intervalMiddleX = event.clientX - that.state.info.lastTouchMiddle_2.x;
					let intervalMiddleY = event.clientY - that.state.info.lastTouchMiddle_2.y;
					intervalMiddleX /= 1;
					intervalMiddleY /= 1;
					let moveLength = intervalMiddleX/this.state.targetCanvas.offsetWidth*that.state.camera.state.orthogonal.width;
					// move x direction
					if (intervalMiddleX > 0.5) {

					    that.state.matrices.translate.data[14] -= moveLength;
					} else if (intervalMiddleX < -0.5) {

					    that.state.matrices.translate.data[14] -= moveLength;
					}
					that.state.info.lastTouchMiddle_2.x = event.clientX;
					
                    moveLength=intervalMiddleY/this.state.targetCanvas.offsetHeight*that.state.camera.state.orthogonal.width/that.state.camera.state.WDH;

					//moveLength/=that.state.camera.state.WDH;
					// move y direction
					if (intervalMiddleY > 0.5) {
					    that.state.matrices.translate.data[13] -= moveLength;
					} else if (intervalMiddleY < -0.5) {
					    that.state.matrices.translate.data[13] -= moveLength;
					}
					that.state.info.lastTouchMiddle_2.y = event.clientY
					
					
				}break;
				case 4:{
					

				}break;
			}

        }
        touchStart(event,that)
        {
            switch (event.touches.length) {
                case 2:
                    that.state.info.lastTouchPosition.x[1] = event.touches[1].clientX;
                    that.state.info.lastTouchPosition.y[1] = event.touches[1].clientY;
                    let temY = event.touches[1].clientY - event.touches[0].clientY;
                    let temX = event.touches[1].clientX - event.touches[0].clientX;
                    that.state.info.lastTouchDistance_2 = temY * temY + temX * temX;
                    that.state.info.lastTouchMiddle_2.x = (event.touches[0].clientX + event.touches[1].clientX) / 2;
                    that.state.info.lastTouchMiddle_2.y = (event.touches[0].clientY + event.touches[1].clientY) / 2;
                    let temRadian = Math.atan2(event.touches[1].clientY - event.touches[0].clientY, event.touches[1]
                        .clientX - event.touches[0].clientX);
                    //window.lastMiddlePosition=[(event.touches[0].clientX+event.touches[1].clientX)/2,(event.touches[0].clientY+event.touches[1].clientY)/2 ]
                    that.state.info.lastTouchRadian_2 = temRadian;
                case 1:
                    that.state.info.lastTouchPosition.x[0] = event.touches[0].clientX;
                    that.state.info.lastTouchPosition.y[0] = event.touches[0].clientY;
                    break;
            }
        }
        touchMove(event,that)
        {

            let temMatrix;

            switch (event.touches.length) {
                case 1:
                    event.touches[0].clientX;
                    let intervalX = event.touches[0].clientX - that.state.info.lastTouchPosition.x[0];
                    let intervalY = event.touches[0].clientY - that.state.info.lastTouchPosition.y[0];
                    let moveAngle = 1.4;
                    // y movement
                    if (intervalY > 0) {
                        temMatrix =  Matrix.getRotate_angle_Z(-moveAngle);
                        that.state.matrices.rotate.data =  Matrix.multiply(temMatrix, that.state.matrices.rotate).data;

                    } else if (intervalY < 0) {
                        temMatrix =  Matrix.getRotate_angle_Z(moveAngle);
                        that.state.matrices.rotate.data =  Matrix.multiply(temMatrix, that.state.matrices.rotate).data;
                    }
                    // x movement
                    if (intervalX > 0) {
                        temMatrix =  Matrix.getRotate_angle_Y(moveAngle);
                        that.state.matrices.rotate.data =  Matrix.multiply(temMatrix, that.state.matrices.rotate).data;

                    } else if (intervalX < 0) {
                        temMatrix =  Matrix.getRotate_angle_Y(-moveAngle);
                        that.state.matrices.rotate.data =  Matrix.multiply(temMatrix, that.state.matrices.rotate).data;
                    }
                    that.state.info.lastTouchPosition.x[0] = event.touches[0].clientX;
                    that.state.info.lastTouchPosition.y[0] = event.touches[0].clientY;

                    break;
                case 2:
                    // get mouse position
                    let temY = event.touches[1].clientY - event.touches[0].clientY;
                    let temX = event.touches[1].clientX - event.touches[0].clientX;
                    let intervalLength = temY * temY + temX * temX - that.state.info.lastTouchDistance_2;
                    // get middle of two touch point
                    let temMiddlePositionX = (event.touches[0].clientX + event.touches[1].clientX) / 2;
                    let temMiddlePositionY = (event.touches[0].clientY + event.touches[1].clientY) / 2;
                    let intervalMiddleX = temMiddlePositionX - that.state.info.lastTouchMiddle_2.x;
                    let intervalMiddleY = temMiddlePositionY - that.state.info.lastTouchMiddle_2.y;
                    intervalMiddleX /= 1;
                    intervalMiddleY /= 1;
                    // get the angle of two touch point
                    let temRadian = Math.atan2(event.touches[1].clientY - event.touches[0].clientY, event.touches[1]
                        .clientX - event.touches[0].clientX);
                    let intervalRadian = temRadian - that.state.info.lastTouchRadian_2;
                    //if(intervalRadian>1.5707963)
                    //{
                    //	intervalRadian=3.1415926-intervalRadian;
                    //}
                    let scaleCoefficient = that.state.camera.state.orthogonal.width/50;
                    if (intervalLength / 50 > 1) {
                        that.state.camera.move("front", scaleCoefficient);
                        that.state.info.lastTouchDistance_2 = temY * temY + temX * temX;
						that.state.matrices.orthogonal.data=that.state.camera.getMatrix4X4_orthogonal().data;
                    } else if (intervalLength / 50 < -1) {
                        that.state.camera.move("back", scaleCoefficient);
						that.state.matrices.orthogonal.data=that.state.camera.getMatrix4X4_orthogonal().data;
                        that.state.info.lastTouchDistance_2 = temY * temY + temX * temX;
                    }
					
					
                    let moveLength = that.state.camera.state.orthogonal.width / 400;
                    // move x direction
                    if (intervalMiddleX > 1) {
                        that.state.matrices.translate.data[14] -= moveLength * intervalMiddleX;
                        that.state.info.lastTouchMiddle_2.x = (event.touches[0].clientX + event.touches[1]
                            .clientX) / 2;
                    } else if (intervalMiddleX < -1) {
                        that.state.matrices.translate.data[14] -= moveLength * intervalMiddleX;
                        that.state.info.lastTouchMiddle_2.x = (event.touches[0].clientX + event.touches[1]
                            .clientX) / 2;
                    }
					//moveLength/=that.state.camera.state.WDH;
                    // move y direction
                    if (intervalMiddleY > 1) {
                        that.state.matrices.translate.data[13] -= moveLength * intervalMiddleY;
                        that.state.info.lastTouchMiddle_2.y = (event.touches[0].clientY + event.touches[1]
                            .clientY) / 2;
                    } else if (intervalMiddleY < -1) {
                        that.state.matrices.translate.data[13] -= moveLength * intervalMiddleY;
                        that.state.info.lastTouchMiddle_2.y = (event.touches[0].clientY + event.touches[1]
                            .clientY) / 2;
                    }

                    if (intervalRadian - 0.02 > 0) {
                        let temMatrix =  Matrix.getRotate_radian_X(-intervalRadian);
                        that.state.matrices.rotate.data =  Matrix.multiply(temMatrix, that.state.matrices.rotate).data;
                        that.state.info.lastTouchRadian_2 = temRadian;
                    } else if (intervalRadian + 0.02 < 0) {
                        let temMatrix =  Matrix.getRotate_radian_X(-intervalRadian);
                        that.state.matrices.rotate.data =  Matrix.multiply(temMatrix, that.state.matrices.rotate).data;
                        that.state.info.lastTouchRadian_2 = temRadian;
                    }

                    that.state.info.lastTouchPosition.x[0] = event.touches[0].clientX;
                    that.state.info.lastTouchPosition.y[0] = event.touches[0].clientY;
                    that.state.info.lastTouchPosition.x[1] = event.touches[1].clientX;
                    that.state.info.lastTouchPosition.y[1] = event.touches[1].clientY;

                    break;



            }
            //document.getElementById("debugText").innerText="ou touchmove me"+(1.5+(window.camera.len-60)/10);
        }

        setEnableWheel(enable=true)
        {
            if(typeof enable=='boolean')
            {
                this.state.control.useWheel=enable;
            }
        }

    }

