export {WebglRenderer,getGlobalWebgl}

    function getGlobalWebgl()
    {
        if(window.webglGlobal==null||window.webglGlobal.initial==false)
        {
            window.webglGlobal= {
                    gl: null,
                    canvas: null,
                    renderFinished:true,
                    initial: false,
                };
            window.webglGlobal.canvas=document.createElement("canvas");
            if(window.webglGlobal.canvas.width<1000)
                window.webglGlobal.canvas.width=800;
            if(window.webglGlobal.canvas.height<1000)
                window.webglGlobal.canvas.height=800;
            window.webglGlobal.gl=window.webglGlobal.canvas.getContext("webgl2");
            window.webglGlobal.initial=true;
        }
		
        return {
            gl: window.webglGlobal.gl,
            canvas:window.webglGlobal.canvas,
        }
    }
	class WebglRenderer
    {
        constructor(renderDom)
        {
            if(this.state!=null&&this.state.isInitialzie)
                return;
            this.state=
            {
                renderCanvas: null,
                targetCanvasInfo: {
                        canvas: null,
                        offset:{
                            x:0,
                            y:0
                        },
                        width:0,
                        height:0,
                    },
                program:null,
                resolution:{
                    width:800,
                    height:800,
                },

                gl:null,
            }

            let globalWebgl=getGlobalWebgl();
            this.state.renderCanvas=globalWebgl.canvas;
            this.state.gl=globalWebgl.gl;
            this.state.initialize=true;
            if(renderDom==null||renderDom.nodeName==null)
            {

            }
            else if(renderDom.nodeName=="CANVAS")
            {
                this.state.targetCanvasInfo.canvas=renderDom;
            }
            else if(renderDom.appendChild!=null) 
            {
                this.state.targetCanvasInfo.canvas=document.createElement("canvas");
                renderDom.appendChild(this.state.targetCanvasInfo.canvas);
            }
            

        }

        setResolution(width,height)
        {
			if(width!=null)
				this.state.resolution.width=width;
			if(height!=null)
				this.state.resolution.height=height;

            let webglInfo=getGlobalWebgl();

            if(this.state.resolution.width>webglInfo.canvas.width)
                webglInfo.canvas.width=this.state.resolution.width;
            if(this.state.resolution.height>webglInfo.canvas.height)
                webglInfo.canvas.height=this.state.resolution.height;

        }

        //
        getResolution()
        {
            return [this.state.resolution.width,this.state.resolution.height];
        }
        setTargetCanvas(newCanvas,width,height,x=0,y=0)
        {
            if(newCanvas?.nodeName=="CANVAS") {
                this.state.targetCanvasInfo.canvas = newCanvas;
                this.state.targetCanvasInfo.offset.x=x;
                this.state.targetCanvasInfo.offset.y=y;
				if(width!=null)
					this.state.targetCanvasInfo.width=width;
				else
					this.state.targetCanvasInfo.width=newCanvas.width;
					
				if(height!=null)
					this.state.targetCanvasInfo.height=height;
				else
					this.state.targetCanvasInfo.height=newCanvas.height;

            }
            else
                this.state.targetCanvas=null;
        }

        renderWithFunction(renderFunction)
        {
            let gl=this.state.gl;
            gl.viewport(0,0,this.state.resolution.width,this.state.resolution.height);

            if(typeof renderFunction=="function") {
                renderFunction();
                let canvasHandle=this.state.targetCanvasInfo.canvas?.getContext("2d");
                canvasHandle?.drawImage(this.state.renderCanvas,0,this.state.renderCanvas.height-this.state.resolution.height,this.state.resolution.width,this.state.resolution.height,this.state.targetCanvasInfo.offset.x,this.state.targetCanvasInfo.offset.y,this.state.targetCanvasInfo.width,this.state.targetCanvasInfo.height);
            }

        }


        // static method , vue don't support
        static getGLHandle()
        {
            return getGlobalWebgl().gl;
        }


    };

